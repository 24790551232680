import {useFetch} from 'use-http'
import {interpolate} from 'react-arc'
import config from '../../../../config/config'

function useMenuOrderNode({token, menu}) {
    const url = interpolate(config.menus.contents, menu)
    const {put, response, loading, error} = useFetch(url, {
        cacheLife: 0,
        cachePolicy: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            'X-Auth-Token': token
        },
    })

    const handleRequest = ({node, body}) => {
        return put('/'+node.id, body)
    }

    return [error, loading, response, handleRequest]
}

export default useMenuOrderNode