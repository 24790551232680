import React, { PureComponent } from "react"
import Page from "../layout/page/Page"
import Panel from "../layout/panel/Panel"
import SignUp from "../components/auth/components/user/SignUp"
import {Jumbo} from '../layout/page/Jumbo'



const Heading = () => {
  return (
    <Jumbo>
      <div style={{height: 200}} />
    </Jumbo>
  )
}

class SignUpPage extends PureComponent {

  render() {



    return (
      <Page header={<Heading />} className="full-width">
        <div className="container">
          <Panel style={{marginTop: -200}} bodyStyle={{padding: 0}}>
            <SignUp/>
          </Panel>
        </div>
      </Page>
    )
  }

}

SignUpPage.propTypes = {}
SignUpPage.defaultProps = {}

export default SignUpPage
