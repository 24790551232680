import React from "react";
import Page from "../../../layout/page/Page";
import BlockList from "./components/BlockList";
import withUserCheck from "../../auth/HOC/withUserCheck";
import { compose } from "redux";
import withUser from "../../auth/HOC/withUser";
import Block from "./components/Block";

const BlockPage = (props) => {
  const id = props.match.params.id;
  return (
    <Page className="full-width">{id ? <Block id={id} /> : <BlockList />}</Page>
  );
};

BlockPage.propTypes = {};
BlockPage.defaultProps = {};

export default compose(withUser, withUserCheck)(BlockPage);
