import React from "react";
import { CircularProgress } from "@mui/material";
import { withStyles } from "@mui/styles";
//import PropTypes from 'prop-types';

const styles = (theme) => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

function Loader({ style }) {
  //const {classes} = props
  //
  return (
    <div
      style={{ position: "relative", ...style }}
      className="animated fadeIn text-center"
    >
      <CircularProgress
        size={40}
        top={0}
        left={0}
        status="loading"
        style={{
          display: "inline-block",
          position: "relative",
        }}
      />
    </div>
  );
}

Loader.propTypes = {};
Loader.defaultProps = {};

export default withStyles(styles)(Loader);
