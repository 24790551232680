import React from 'react'
import withUser from '../../../auth/HOC/withUser'
import Foods from '../../foods/components/Foods'

function FoodPicker({user, onItemClick, showFilter, containerData}) {
    return (
        <Foods containerData={containerData} showFilter={showFilter} id={user.id} onItemClick={onItemClick}/>
    )
}


export default withUser(FoodPicker)
