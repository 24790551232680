import React, { useState } from "react";
import Panel from "../../../../layout/panel/Panel";
import MediumPage from "../../../../layout/page/MediumPage";
import { configUserBoard } from "../arc/UserBoard";
import withToken from "../../../auth/HOC/withToken";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import { withUseARC } from "react-arc";
import navigate from "../../../../singletons/navigate";
import commons from "../commons";
import Drawer from "@mui/material/Drawer";
import MenuPicker from "./MenuPicker";
import Loader from "../../../../layout/loader/Loader";
import CalloutComponent from "../../../../layout/error/CalloutComponent";
import useBoardOrderNode from "../hooks/useBoardOrderNode";
import { Add } from "@mui/icons-material";
import { Column, Row } from "../../../../layout/grid";
import BigButton from "../../../../layout/buttons/BigButton";
import BoardLink from "./BoardLink";
import useItemPicker from "../../../hooks/useItemPicker";
import useBoardAddContents from "../hooks/useBoardAddContents";
import useBoardDeleteNode from "../hooks/useBoardDeleteNode";
import useBoardPublish from "../hooks/useBoardPublish";
import BoardQrCode from "./BoardQrCode";
import Placeholder from "../../menus/components/menu/Placeholder";
import MenuHeader from "../../menus/components/MenuHeader";
import Edit from "./Edit";
import MenuContents from "../../menus/components/menu/MenuContents";
import withConfirmDialog from "../../../../layout/confirm/withConfirmDialog";
import BoardPrintQrCode from "./BoardPrintQrCode";
import useBoardAddBlocks from "../hooks/useBoardAddBlocks";
import BlockPicker from "./BlockPicker";
import config from "../../../../config/config";

function Board({ response = {}, arc, token, setConfirm }) {
  const [category, setCategory] = useItemPicker();
  const [mode, setMode] = useState(null);

  const baseParams = {
    token,
    data: response,
  };

  const handleDelete = () => {
    setConfirm({
      title: "Merci de confirmer",
      labelAccept: "Supprimer",
      labelReject: "Annuler",
      callback: () => arc.remove().then(() => navigate(commons.basePath)),
    });
  };

  const handleMode = (mode, category_id) => {
    setCategory(category_id);
    setMode(mode);
  };

  const [onAddBlockError, onAddBlockLoading, , addBlock] = useBoardAddBlocks({
    ...baseParams,
  });
  const [onAddError, onAddLoading, , addContents] = useBoardAddContents({
    ...baseParams,
  });
  const [onDeleteError, onDeleteLoading, , deleteNode] = useBoardDeleteNode({
    ...baseParams,
    baseURL: config.boards.contents,
  });
  const [onDeleteBlockError, onDeleteBlockLoading, , deleteBlock] =
    useBoardDeleteNode({
      ...baseParams,
      baseURL: config.boards.blocks,
    });

  const [onOrderError, onOrderLoading, , orderNode] = useBoardOrderNode({
    ...baseParams,
    baseURL: config.boards.contents,
  });

  const [onOrderBlockError, onOrderBlockLoading, , orderBlock] =
    useBoardOrderNode({
      ...baseParams,
      baseURL: config.boards.blocks,
    });

  const [onPublishError, onPublishLoading, , publishBoard] = useBoardPublish({
    ...baseParams,
    baseURL: config.boards.contents,
  });

  const handleAddCategory = ({ id }) => {
    addContents({ id, parent: category }).then(() => arc.get());
  };
  const handleAddBlock = ({ id, type = "custom" }) => {
    addBlock({ id, type }).then(() => arc.get());
  };

  const handleDeleteNode = (node) => {
    deleteNode({ node }).then(() => arc.get());
  };
  const handleDeleteBlock = (node) => {
    deleteBlock({ node }).then(() => arc.get());
  };
  const handleOrderNode = (body) => {
    orderNode({ node: response, body }).then(() => arc.get());
  };
  const handleOrderBlock = (body) => {
    orderBlock({ node: response, body }).then(() => arc.get());
  };

  const hasError =
    onPublishError ||
    onAddError ||
    onDeleteError ||
    onOrderError ||
    onAddBlockError ||
    onDeleteBlockError ||
    onOrderBlockError;
  const isLoading =
    onPublishLoading ||
    onAddLoading ||
    onDeleteLoading ||
    onOrderLoading ||
    onAddBlockLoading ||
    onDeleteBlockLoading ||
    onOrderBlockLoading;

  return (
    <MediumPage containerStyle={{ maxWidth: "100%" }}>
      <Drawer anchor={"right"} open={!!mode} onClose={() => setMode(null)}>
        {mode === "menus" && (
          <React.Fragment>
            <div style={{ marginTop: 50, padding: 20 }}>
              <div className="ml-20 small-title">Menus</div>
              <MenuPicker showCreate={false} onItemClick={handleAddCategory} />
            </div>

            <div style={{ padding: 20 }}>
              <div className="ml-20 small-title">Séparateurs</div>
              <MenuPicker
                type={"title"}
                showCreate={false}
                onItemClick={handleAddCategory}
              />
            </div>
          </React.Fragment>
        )}
        {mode === "blocks" && (
          <React.Fragment>
            <div style={{ marginTop: 50, padding: 20 }}>
              <div className="ml-20 small-title">Personnalisation</div>
              <BlockPicker showCreate={false} onItemClick={handleAddBlock} />
            </div>
          </React.Fragment>
        )}
      </Drawer>
      <Row>
        <Column size={9}>
          <Panel bodyStyle={{ padding: 0, overflow: "hidden" }}>
            {isLoading && (
              <Loader style={{ position: "absolute", top: 10, left: 10 }} />
            )}
            {!!hasError && <CalloutComponent error={hasError} />}

            <MenuHeader
              isBoard
              data={response}
              EditComponent={Edit}
              onSuccess={() => arc.get()}
            />

            <MenuContents
              editButtonText={"Modifier Mise en page"}
              id={null}
              handleOrderNode={handleOrderBlock}
              handleMode={handleMode}
              onClickDelete={handleDeleteBlock}
              contents={response.blocks}
            >
              <Placeholder onClick={() => handleMode("blocks")}>
                <Add />
                <span style={{ marginLeft: 20 }}>Ajouter un élément</span>
              </Placeholder>
              {/*<Placeholder onClick={() => handleMode('food')}>*/}
              {/*    <span>Ajouter un plat</span>*/}
              {/*</Placeholder>*/}
            </MenuContents>

            <MenuContents
              editButtonText={"Modifier le menu"}
              id={null}
              handleOrderNode={handleOrderNode}
              handleMode={handleMode}
              onClickDelete={handleDeleteNode}
              contents={response.contents}
            >
              <Placeholder onClick={() => handleMode("menus")}>
                <Add />
                <span style={{ marginLeft: 20 }}>Ajouter un élément</span>
              </Placeholder>
              {/*<Placeholder onClick={() => handleMode('food')}>*/}
              {/*    <span>Ajouter un plat</span>*/}
              {/*</Placeholder>*/}
            </MenuContents>
          </Panel>

          <Panel title={"Edition"}>
            <BigButton
              style={{ color: "#E33", width: "100%" }}
              variant={"contained"}
              color="primary"
              onClick={handleDelete}
            >
              Supprimer
            </BigButton>
          </Panel>
        </Column>
        <Column size={3}>
          <Panel title={"QR-Code de votre menu"} className="animated fadeIn">
            <BoardQrCode id={response.id} />
            <BoardPrintQrCode id={response.id} />
          </Panel>
          <Panel title={"Visualiser"} className="animated fadeIn">
            <BoardLink id={response.id} />
          </Panel>

          <Panel title={"Publication"}>
            <BigButton
              style={{ width: "100%", marginBottom: 10 }}
              onClick={publishBoard}
            >
              Publier
            </BigButton>
          </Panel>
        </Column>
      </Row>
    </MediumPage>
  );
}

Board.propTypes = {};
Board.defaultProps = {};

export default withToken(
  withUseARC(configUserBoard)(withARCLoader(withConfirmDialog(Board)))
);
