import React from "react";
import { Route } from "react-router";
import MenuTitlePage from "./MenuTitlePage";

const RouterMenusTitle = () => {
  return (
    <Route
      exact
      path="/menus-titles/:id?"
      name="Menu Title"
      component={MenuTitlePage}
    />
  );
};

export default RouterMenusTitle;
