import React, { useEffect, useState } from "react";
//import PropTypes from 'prop-types'
import CategoryItem from "./CategoryItem";
import FoodItem from "./FoodItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragIndicator, Edit } from "@mui/icons-material";
import BigButton from "../../../../../layout/buttons/BigButton";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

//const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  //padding: grid * 2,
  //margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  //background: isDragging ? 'lightgreen' : 'grey',
  transition: "boxShadow 300ms ease-out",
  //border: "1px solid #DEDEDE",
  boxShadow: isDragging ? "0 2px 10px rgba(0,0,0,.3)" : "",
  //borderRadius: 3,
  margin: 5,
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  //backgroundColor:'#FFF',
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  // padding: grid,
  // width: 250
});

function MenuContents({
  editButtonText = "Modifier le menu",
  children,
  id,
  contents,
  onClickDelete,
  handleMode,
  handleOrderNode,
  editable = true,
  editing,
}) {
  const [state, setState] = useState({ contents });
  const [editingState, setEditing] = useState(false);

  const toggleEditing = () => setEditing(!editingState);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newContents = reorder(
      state.contents,
      result.source.index,
      result.destination.index
    );
    handleOrderNode({
      parent_id: id,
      order: newContents.map((p) => p.id),
    });
    setState({
      contents: newContents || [],
    });
  };

  useEffect(() => {
    setState({
      contents: contents || [],
    });
  }, [contents]);

  const isEditing = editing || (editable && editingState);

  const classNames = ["mg-menu-contents"];
  if (isEditing) classNames.push("editing");

  return (
    <React.Fragment>
      {!id && (
        <div className="edit-toolbar">
          <BigButton
            onClick={toggleEditing}
            style={{
              display: "flex",
              marginBottom: 0,
              width: "auto",
              boxShadow: "0 1px 1px rgba(0,0,0,.2)",
            }}
          >
            <Edit style={{ fontSize: 16, marginRight: 20 }} />
            <span>{isEditing ? "Terminer" : editButtonText}</span>
          </BigButton>
        </div>
      )}
      <div className={classNames.join(" ")}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className="mg-menu-items"
              >
                {/*style={{position: 'absolute', top: 10, right: 10, zIndex: 2}}*/}

                {state.contents.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div
                          key={item.id}
                          className={
                            "menu-draggable " +
                            (isEditing ? "menu-draggable-active" : "")
                          }
                        >
                          <div
                            {...provided.dragHandleProps}
                            className={
                              "menu-drag-handle " + (!isEditing ? "hidden" : "")
                            }
                          >
                            <DragIndicator />
                          </div>

                          {item.type === "category" ? (
                            <CategoryItem
                              item={item}
                              editing={isEditing}
                              handleOrderNode={handleOrderNode}
                              handleMode={handleMode}
                              onClickDelete={onClickDelete}
                            />
                          ) : (
                            <FoodItem
                              type={item.type}
                              editing={isEditing}
                              item={item}
                              onClick={() => onClickDelete(item)}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isEditing && children}
      </div>
    </React.Fragment>
  );
}

MenuContents.propTypes = {};
MenuContents.defaultProps = {};

export default MenuContents;
