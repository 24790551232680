import React, { useState } from "react";
//import PropTypes from 'prop-types'
import { List } from "@mui/material";
import MediumPage from "../../../../layout/page/MediumPage";
import Panel from "../../../../layout/panel/Panel";
import Button from "@mui/material/Button";
import useToggle from "../../../hooks/useToggle";
import { compose } from "redux";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import withUser from "../../../auth/HOC/withUser";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import withToken from "../../../auth/HOC/withToken";
import navigate from "../../../../singletons/navigate";
import EditBoardDialog from "./EditBoardDialog";
import commons from "../commons";
import Welcome from "../../../home/Welcome";
import withUserBoards from "../arc/userMenus";
import colorHash from "../../../../layout/colors/colorHash";
import { Add } from "@mui/icons-material";

export const Boards = compose(
  withToken,
  withUserBoards,
  withARCLoader
)(({ model, fetch, size = 200, showCreate = true }) => {
  const [visible, setVisible] = useToggle(false);
  const [boardId, setBoardId] = useState(null);
  const onSuccess = () => {
    fetch();
    setVisible(false);
    setBoardId(null);
  };
  return (
    <div>
      <EditBoardDialog
        duplicate={boardId}
        open={visible}
        onClose={() => {
          setVisible(false);
          setBoardId(null);
        }}
        onSuccess={onSuccess}
      />
      <List className="menu-go-list">
        {model.slice(0, size).map((item) => (
          <ListItem
            key={item.id}
            button
            className="menu-go-list-item"
            secondaryAction={
              showCreate ? (
                <Button
                  onClick={() => {
                    setBoardId(item.id);
                    setVisible(true);
                  }}
                >
                  Dupliquer <Add />
                </Button>
              ) : undefined
            }
          >
            <ListItemAvatar
              onClick={() => navigate(commons.basePath + "/" + item.id)}
            >
              <Avatar style={{ backgroundColor: colorHash.hex(item.title) }}>
                {item.title?.[0] || ""}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              onClick={() => navigate(commons.basePath + "/" + item.id)}
              primary={<strong>{item.title}</strong>}
              secondary={item.description?.substr(0, 50)}
            />
          </ListItem>
        ))}
      </List>
      {showCreate && (
        <Button
          onClick={() => setVisible(true)}
          style={{ display: "block", width: "100%" }}
        >
          Créer une nouvelle {commons.singular}
        </Button>
      )}
    </div>
  );
});

function BoardList({ user }) {
  //const [item, setItem] = useItemPicker(null)
  return (
    <MediumPage header={<Welcome />} containerStyle={{ maxWidth: "100%" }}>
      <h1 style={{ color: "#FFF" }}>Vos {commons.plural}</h1>
      <p style={{ color: "#FFF" }}>Liste de vos {commons.plural}</p>
      <Panel bodyStyle={{ padding: 0 }}>
        <Boards id={user.id} />
      </Panel>
    </MediumPage>
  );
}

BoardList.propTypes = {};
BoardList.defaultProps = {};

export default withUser(BoardList);
