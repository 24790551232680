import React from "react";

/** REDUX **/
import { Provider } from "react-redux";
import store from "./config/store";

/** THEME **/
import router from "./config/router";
import ClientComponent from "./components/client/component/ClientComponent";
import withRoot from "./withRoot";

function App() {
  return (
    <Provider store={store}>
      <ClientComponent>{router}</ClientComponent>
    </Provider>
  );
}

export default withRoot(App);
