import React, { useState } from "react";
import withToken from "../../../auth/HOC/withToken";
import withUserFood from "../arc/UserFood";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import useToggle from "../../../hooks/useToggle";
import EditFoodDialog from "./EditFoodDialog";
import { List, ListItemIcon } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import navigate from "../../../../singletons/navigate";
import commons from "../commons";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Field from "../../../../layout/inputs/Field";
import useFormState from "../../../hooks/useFormState";
import { Add, CheckCircle } from "@mui/icons-material";
import EmojiLang from "../../shared/EmojiLang";

function getDuplicateData(model) {
  if (!model) return {};
  const {
    title,
    description,
    lang,
    price,
    price_icon,
    price_desc,
    price_2,
    price_icon_2,
    price_desc_2,
  } = model;
  return {
    title,
    description,
    lang,
    price,
    price_icon,
    price_desc,
    price_2,
    price_icon_2,
    price_desc_2,
  };
}

const Foods = ({
  model,
  fetch,
  onItemClick,
  size = 200,
  showCreate = true,
  showFilter = false,
  containerData,
}) => {
  const [visible, setVisible] = useToggle(false);
  const [duplicateId, setDuplicateId] = useState(null);
  const [form, setForm] = useFormState({ filter: "" });
  const onSuccess = () => {
    fetch();
    setVisible(false);
    setDuplicateId(null);
  };

  const items = model.slice(0, size).reduce((state, item) => {
    if (form.filter) {
      const found =
        item.title.toLowerCase().indexOf(form.filter.toLowerCase()) > -1 ||
        (item.description?.toLowerCase() || "").indexOf(
          form.filter.toLowerCase()
        ) > -1;
      return found ? state.concat(item) : state;
    }
    return state.concat(item);
  }, []);

  const isAlreadyPicked = (node, id) => {
    if (!node || !node.contents || node.contents.length < 1) return false;
    return node.contents.some((p) => {
      return p.food_id === id || isAlreadyPicked(p, id);
    });
  };

  return (
    <div>
      <EditFoodDialog
        duplicate={duplicateId}
        duplicateData={getDuplicateData(
          model.find((item) => item.id === duplicateId)
        )}
        open={visible}
        onClose={() => {
          setVisible(false);
          setDuplicateId(null);
        }}
        onSuccess={onSuccess}
      />

      {showFilter && (
        <div style={{ padding: 20 }}>
          <Field
            model={form}
            prop={"filter"}
            placeholder={"Filter les plats"}
            onChange={setForm}
          />
        </div>
      )}

      <List className="menu-go-list">
        {items.map((item) => (
          <ListItem
            key={item.id}
            button
            className={"menu-go-list-item"}
            secondaryAction={
              showCreate ? (
                <Button
                  onClick={() => {
                    setDuplicateId(item.id);
                    setVisible(true);
                  }}
                >
                  Dupliquer <Add />
                </Button>
              ) : undefined
            }
          >
            <ListItemIcon
              onClick={() =>
                onItemClick
                  ? onItemClick(item)
                  : navigate(commons.basePath + "/" + item.id)
              }
            >
              <EmojiLang model={item} />
            </ListItemIcon>
            <ListItemText
              onClick={() =>
                onItemClick
                  ? onItemClick(item)
                  : navigate(commons.basePath + "/" + item.id)
              }
              primary={item.title}
              primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              secondary={item.description?.substr(0, 50)}
            />

            {isAlreadyPicked(containerData, item.id) && (
              <ListItemIcon
                onClick={() =>
                  onItemClick
                    ? onItemClick(item)
                    : navigate(commons.basePath + "/" + item.id)
                }
                children={<CheckCircle />}
              />
            )}
          </ListItem>
        ))}
      </List>
      {showCreate && (
        <Button
          onClick={() => setVisible(true)}
          style={{ display: "block", width: "100%" }}
        >
          Créer un nouveau {commons.singular}
        </Button>
      )}
    </div>
  );
};

export default withToken(withUserFood(withARCLoader(Foods)));
