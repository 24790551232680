import React from "react";
//import PropTypes from 'prop-types'
import { Divider } from "@mui/material";
import Edit from "./Edit";
import Dialog from "@mui/material/Dialog";

function EditBoardDialog({ id, duplicate, open, onClose, onSuccess }) {
  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
      <div style={{ padding: "20px 40px" }}>
        <h4>{!!duplicate ? "Dupliquer" : "Éditer"}</h4>
      </div>
      <Divider />
      <div style={{ minHeight: 200 }}>
        <div style={{ padding: "20px 40px" }}>
          <Edit
            {...(id ? { id } : {})}
            isBoard={true}
            duplicate={duplicate}
            onSuccess={onSuccess}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default EditBoardDialog;
