import {
  storeSession,
  loadSession,
  destroySession,
  doLogin,
  doLogout,
  autoDisconnect,
} from "../core/authTools";

export function reloadSession(params) {
  return function (dispatch) {
    dispatch({ type: "AUTH_RELOAD_SESSION", payload: params });
    return loadSession()
      .then((response) => {
        const payload = {
          profile: { ...response.profile },
          expires: response.expires,
          token: response.token,
          isAdmin: response.isAdmin,
        };
        dispatch({ type: "AUTH_RELOAD_SESSION_SUCCESSFUL", payload });
        autoDisconnect({
          expires: response.expires * 1000,
          disconnect: () => dispatch(destroyCurrentSession(response.token)),
        });
        return Promise.resolve();
      })
      .catch((error) => {
        //console.log(error)
        dispatch({ type: "AUTH_RELOAD_SESSION_REJECTED", payload: {} });
        return Promise.resolve();
      });
  };
}

export function destroyCurrentSession(token) {
  return function (dispatch) {
    doLogout({ token })
      .then(() => {
        destroySession();
        dispatch({ type: "AUTH_DESTROY_SESSION", payload: {} });
      })
      .catch((e) => {
        console.log(e);
        destroySession();
        dispatch({ type: "AUTH_DESTROY_SESSION", payload: {} });
      });
  };
}

export function logout(params) {
  return function (dispatch) {
    console.log(params);
    // doLogout({
    //
    // })
    dispatch({ type: "AUTH_LOGOUT", payload: params });
  };
}

export function authDirectToken(params) {
  return function (dispatch) {
    dispatch({ type: "AUTH_LOGIN", payload: params });
    doLogin(params)
      .then((response) => {
        console.log(response);
        const { token, expires } = response.data.token;
        const payload = {
          // auth_token: token,
          token: token,
          profile: { ...response.data.user },
          expires: expires,
          //roles: token.GRS,
          isAdmin: response.data.user.isAdmin,
          //isGranted: token.GRS.includes("PHENIX_WS_USER")
        };
        dispatch({ type: "AUTH_LOGIN_SUCCESSFUL", payload });
        storeSession(payload);
        autoDisconnect({
          expires: expires * 1000,
          disconnect: () => dispatch(destroyCurrentSession(token)),
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "AUTH_LOGIN_REJECTED", payload: { error } });
      });
  };
}
