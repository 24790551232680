import React from "react";

import FoodList from "./components/FoodList";
import withUserCheck from "../../auth/HOC/withUserCheck";
import { compose } from "redux";
import withUser from "../../auth/HOC/withUser";
import Food from "./components/Food";

const FoodPage = (props) => {
  const id = props.match.params.id;
  return id ? <Food id={id} /> : <FoodList />;
};

FoodPage.propTypes = {};
FoodPage.defaultProps = {};

export default compose(withUser, withUserCheck)(FoodPage);
