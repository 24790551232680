import React  from "react"
import UserContainer from "../containers/UserContainer"


const ComponentLoader =  ({ isLogged, user,originalProps, userChecked, Wrapped }) => {
  const data = {
    isLogged,
    user,
    userChecked,
    userId: isLogged ? user.id : undefined
  }
  return <Wrapped {...data} {...originalProps} />
}

function withUser(Wrapped) {
  return function Loader (props) {
    return (<UserContainer Wrapped={Wrapped} originalProps={props} {...props} component={ComponentLoader}/>)
  }
}


withUser.propTypes = {}
withUser.defaultProps = {}

export default withUser