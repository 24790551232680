import React  from "react"
import { Route } from "react-router"
import BoardPage from './BoardPage'
import commons from './commons'

const RouterBoards = () => {

  return (
      <Route exact path={commons.basePath + '/:id?'} name={commons.singular} component={BoardPage}/>
  )
}

RouterBoards.propTypes = {}
RouterBoards.defaultProps = {}

export default RouterBoards
