import React, { Component } from "react"

import PageHome from "./PageHome"

class RouterHome extends Component {
  render() {
    return (
      <PageHome/>
    )
  }
}

RouterHome.propTypes = {}
RouterHome.defaultProps = {}

export default RouterHome
