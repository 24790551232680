import React from "react";
import Panel from "../../../../layout/panel/Panel";
import MediumPage from "../../../../layout/page/MediumPage";
import withToken from "../../../auth/HOC/withToken";
import { compose } from "redux";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import { withUseARC } from "react-arc";
import navigate from "../../../../singletons/navigate";
import { Column, Row } from "../../../../layout/grid";
import commons from "../commons";
import { configUserBlock } from "../arc/UserBlock";
import useToggle from "../../../hooks/useToggle";
//import EditBlockDialog from "./EditBlockDialog";
import BigButton from "../../../../layout/buttons/BigButton";
import Edit from "./Edit";

function Block({ response, arc }) {
  const [visible, setVisible] = useToggle(false);

  const handleDelete = () =>
    arc.remove().then(() => navigate(commons.basePath));
  // const handleOther = () => {
  //     console.log('hello')
  // }
  const handleEdit = () => setVisible(true);
  const closeHandler = () => setVisible(false);

  return (
    <MediumPage containerStyle={{ maxWidth: "100%" }}>
      {/*<EditBlockDialog*/}
      {/*  id={response.id}*/}
      {/*  open={visible}*/}
      {/*  onClose={closeHandler}*/}
      {/*  onSuccess={() => {*/}
      {/*    closeHandler();*/}
      {/*    arc.get();*/}
      {/*  }}*/}
      {/*/>*/}
      <Row>
        <Column size={9}>
          <Panel>
            <Row>
              <Column size={12}>
                <div>
                  <strong>{response.title}</strong>
                </div>
                {response.content && (
                  <div className="text-muted">
                    {response.content.slice(0, 100)}
                  </div>
                )}
              </Column>
            </Row>
          </Panel>
        </Column>
        <Column size={3}>
          <Panel>
            <BigButton
              style={{ width: "100%", marginBottom: 10 }}
              onClick={handleEdit}
            >
              Modifier
            </BigButton>
            <BigButton
              style={{ color: "#E33", width: "100%" }}
              variant={"contained"}
              color="primary"
              onClick={handleDelete}
            >
              Supprimer
            </BigButton>
          </Panel>
        </Column>
      </Row>

      {visible && (
        <Row>
          <Column size={9}>
            <Panel>
              <Edit
                {...(response.id ? { id: response.id } : {})}
                onSuccess={() => {
                  closeHandler();
                  arc.get();
                }}
              />
            </Panel>
          </Column>
        </Row>
      )}
    </MediumPage>
  );
}

export default compose(
  withToken,
  withUseARC(configUserBlock),
  withARCLoader
)(Block);
