import React from "react";
import { Button } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import store from "../../../config/store";
import { destroyCurrentSession } from "../actions/authActions";

const SignoutComponent = () => {
  return (
    <Button
      onClick={() => {
        store.dispatch(destroyCurrentSession());
      }}
      icon={<ExitToApp />}
      label={"Sign out"}
    />
  );
};

export default SignoutComponent;
