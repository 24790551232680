import React from "react";
import Welcome from "./Welcome";
import { Column, Row } from "../../layout/grid";
import Panel from "../../layout/panel/Panel";
import { Menus } from "../editor/menus/components/MenuList";
import withUser from "../auth/HOC/withUser";
import Categories from "../editor/categories/components/Categories";
import Foods from "../editor/foods/components/Foods";
import MediumPage from "../../layout/page/MediumPage";
import BigButton from "../../layout/buttons/BigButton";
import navigate from "../../singletons/navigate";
import { Boards } from "../editor/boards/components/BoardList";

const styles = {
  button: { boxShadow: "0 1px 2px rgba(0,0,0,.1)" },
};

const HomeContent = withUser(({ user }) => {
  if (!user) return null;
  return (
    <React.Fragment>
      <p style={{ color: "#FFF" }}>Tableau de controle</p>
      <Row>
        <Column size={9}>
          <Panel bodyStyle={{ padding: 0 }} title={"Vos dernières cartes"}>
            <Boards id={user.id} showCreate={false} size={3} />
            <div style={{ padding: "10px 10px 0 10px" }}>
              <BigButton
                onClick={() => navigate("/boards")}
                style={styles.button}
              >
                voir toutes les cartes
              </BigButton>
            </div>
          </Panel>
        </Column>
        <Column size={9}>
          <Panel bodyStyle={{ padding: 0 }} title={"Vos derniers menus"}>
            <Menus id={user.id} showCreate={false} size={3} />
            <div style={{ padding: "10px 10px 0 10px" }}>
              <BigButton
                onClick={() => navigate("/menus")}
                style={styles.button}
              >
                voir tous les menus
              </BigButton>
            </div>
          </Panel>
        </Column>
        <Column size={9}>
          <Panel bodyStyle={{ padding: 0 }} title={"Vos dernières categories"}>
            <Categories id={user.id} showCreate={false} size={3} />
            <div style={{ padding: "10px 10px 0 10px" }}>
              <BigButton
                onClick={() => navigate("/categories")}
                style={styles.button}
              >
                voir toutes les categories
              </BigButton>
            </div>
          </Panel>
        </Column>
        <Column size={9}>
          <Panel bodyStyle={{ padding: 0 }} title={"Vos derniers plats"}>
            <Foods id={user.id} showCreate={false} size={3} />
            <div style={{ padding: "10px 10px 0 10px" }}>
              <BigButton
                onClick={() => navigate("/foods")}
                style={styles.button}
              >
                voir tous les plats
              </BigButton>
            </div>
          </Panel>
        </Column>
      </Row>
    </React.Fragment>
  );
});

function PageHome() {
  return (
    <MediumPage header={<Welcome />} containerStyle={{ maxWidth: "100%" }}>
      <h1 style={{ color: "#FFF" }}>Bienvenue !</h1>
      <HomeContent />
    </MediumPage>
  );
}

PageHome.propTypes = {};
PageHome.defaultProps = {};

export default PageHome;
