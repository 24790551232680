import React from "react";
import Page from "./Page";
import { Jumbo } from "./Jumbo";

const OFFSET = 200;

const styles = {
  offset: { maxWidth: "400px", margin: `-${OFFSET}px auto 0 auto` },
};

const Heading = () => {
  return (
    <Jumbo>
      <div style={{ height: 100 }} />
    </Jumbo>
  );
};

function MediumPage({ children, containerStyle = {} }) {
  return (
    <Page header={<Heading />} className="full-width">
      <div className="container">
        <div style={{ ...styles.offset, ...containerStyle }}>{children}</div>
      </div>
    </Page>
  );
}

MediumPage.propTypes = {};
MediumPage.defaultProps = {};

export default MediumPage;
