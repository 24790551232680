import React from "react";
//import PropTypes from 'prop-types'
import { Button } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import withUser from "../../../auth/HOC/withUser";
import { compose } from "redux";
import { interpolate } from "react-arc";
import config from "../../../../config/config";

const getURL = ({ userName, boardId }) => {
  return interpolate(config.desk, { userName: userName, boardId: boardId });
};

function BoardLink({ user, id }) {
  const url = getURL({ userName: user.username, boardId: id });
  const nav = () => window.open(url, "_blank");
  return (
    <Button target="_blank" onClick={nav}>
      Prévisualisez votre menu <OpenInNew />
    </Button>
  );
}

BoardLink.propTypes = {};
BoardLink.defaultProps = {};

export default compose(withUser)(BoardLink);
