import React from "react";
import { RemoveCircle } from "@mui/icons-material";
import FoodPrice from "../../../foods/components/FoodPrice";
import { Column } from "../../../../../layout/grid";
import Row from "../../../../../layout/grid/Row";
import IconButton from "@mui/material/IconButton";

const styles = {
  foods: {
    padding: "10px 20px",
    backgroundColor: "#F9F9F9",
    //textAlign: 'center',
    //border: '1px solid #F0F0F0',

    borderRadius: 3,
  },
  remove: {},
  icon: {
    color: "#E33",
  },
};

const FoodItem = ({ item, type, onClick, editing = true }) => {
  const { title, description } = item;
  const hasPrice = !!item.price;
  return (
    <div className="menu-food" style={styles.foods}>
      <Row>
        <Column
          className={type === "title" ? "text-center" : ""}
          size={hasPrice ? 9 : 12}
        >
          <div>
            <strong>{title}</strong>
          </div>
          {description && <div className="text-muted">{description}</div>}
        </Column>
        {hasPrice && (
          <Column size={3}>
            <FoodPrice price={item.price} />
          </Column>
        )}
      </Row>

      {editing && (
        <div
          className="animated fadeIn menu-offset-button"
          style={styles.remove}
        >
          <IconButton size={"small"} onClick={onClick}>
            <RemoveCircle style={styles.icon} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

FoodItem.propTypes = {};
FoodItem.defaultProps = {};

export default FoodItem;
