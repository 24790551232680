import React from 'react'

const currency = '€'
const FoodPrice = ({price}) => {
    if(!price) return null
    const priceParsed = parseFloat(price)
    const [units, decimals] = priceParsed.toFixed(2).split('.')
    return (<div className="price-tag">
        <div className="food-price">
            <span className="units">{units}</span>
            <span className="decimals">{currency}{decimals}</span>
        </div>
    </div>)
}

export default FoodPrice
