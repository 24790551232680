import React from 'react'
//import PropTypes from 'prop-types'
import {reloadSession} from '../actions/authActions'
import {destroyCurrentSession} from '../actions/authActions'

class AbstractAuthComponent extends React.Component {

  disconnect = () => {
    this.props.dispatch(destroyCurrentSession())
  }

  getProfile(_props) {
    const props = _props || this.props
    return props.user.profile
  }

  isAdmin(_props) {
    const props = _props || this.props
    return this.isLogged(props) && this.getProfile(props).isAdmin
  }

  remainingTime(_props) {
    const props = _props || this.props
    return props.user.expires * 1000 - new Date().getTime()
  }

  isExpired(_props) {
    const props = _props || this.props
    return this.remainingTime(props) < 0
  }


  isLogged(_props) {
    //user logged once successfully
    const props = _props || this.props
    //console.log(this.remainingTime(props), (props.user.credentialValid || props.token), props.user.expires)
    return (props.user.credentialValid || props.token) && !this.isExpired(props)
  }

  userChecked(){
    return this.props.user.sessionCheck
  }

  componentDidMount() {
    if (!this.props.user.sessionCheck) {
      //checking previous auth
      this.props.dispatch(reloadSession())
    }
  }

}

AbstractAuthComponent.propTypes = {

}
AbstractAuthComponent.defaultProps = {

}


export default AbstractAuthComponent
