import React, { useState } from "react";
import Panel from "../../../../layout/panel/Panel";
import MediumPage from "../../../../layout/page/MediumPage";
import { configUserMenu } from "../arc/UserMenu";
import withToken from "../../../auth/HOC/withToken";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import { withUseARC } from "react-arc";
import navigate from "../../../../singletons/navigate";
import { commons, commons_menu_title } from "../commons";
import Drawer from "@mui/material/Drawer";
import CategoryPicker from "./CategoryPicker";
import FoodPicker from "./FoodPicker";
import useMenuAddContents from "../hooks/useMenuAddContents";
import Loader from "../../../../layout/loader/Loader";
import useMenuDeleteNode from "../hooks/useMenuDeleteNode";
import CalloutComponent from "../../../../layout/error/CalloutComponent";
import useItemPicker from "../../../hooks/useItemPicker";
import MenuContents from "./menu/MenuContents";
import useMenuOrderNode from "../hooks/useMenuOrderNode";
import "./menu.scss";
import Placeholder from "./menu/Placeholder";
import { Add, Delete } from "@mui/icons-material";
import MenuHeader from "./MenuHeader";
import BigButton from "../../../../layout/buttons/BigButton";
import withConfirmDialog from "../../../../layout/confirm/withConfirmDialog";
import { compose } from "redux";

const connector = compose(
  withToken,
  withUseARC(configUserMenu),
  withARCLoader,
  withConfirmDialog
);

export const Menu = connector(
  ({ response, type, arc, token, setConfirm, onDelete }) => {
    const [category, setCategory] = useItemPicker();
    const [mode, setMode] = useState(null);

    const handleDelete = () => {
      setConfirm({
        title: "Merci de confirmer",
        labelAccept: "Supprimer",
        labelReject: "Annuler",
        callback: () =>
          arc.remove().then(() => {
            if (onDelete) onDelete();
            navigate(
              type === "menu" ? commons.basePath : commons_menu_title.basePath
            );
          }),
      });
    };
    const handleMode = (mode, category_id) => {
      setCategory(category_id);
      setMode(mode);
    };
    const [onAddError, onAddLoading, , addContents] = useMenuAddContents({
      token,
      menu: response,
    });
    const [onDeleteError, onDeleteLoading, , deleteNode] = useMenuDeleteNode({
      token,
      menu: response,
    });

    const [onOrderError, onOrderLoading, , orderNode] = useMenuOrderNode({
      token,
      menu: response,
    });

    const handleAddCategory = ({ id }) => {
      addContents({ type: "category", id, parent: category }).then(() =>
        arc.get()
      );
    };
    const handleAddFood = ({ id }) => {
      addContents({ type: "food", id, parent: category }).then(() => arc.get());
    };
    const handleDeleteNode = (node) => {
      setConfirm({
        title: "Merci de confirmer",
        labelAccept: "Supprimer",
        labelReject: "Annuler",
        callback: () => deleteNode({ node }).then(() => arc.get()),
      });
    };
    const handleOrderNode = (body) => {
      orderNode({ node: response, body }).then(() => arc.get());
    };

    const hasError = onAddError || onDeleteError || onOrderError;
    const isLoading = onAddLoading || onDeleteLoading || onOrderLoading;

    return (
      <React.Fragment>
        <Drawer anchor={"right"} open={!!mode} onClose={() => setMode(null)}>
          <div style={{ minWidth: 320, width: 320, padding: 20 }}>
            {mode === "category" ? (
              <CategoryPicker
                containerData={response}
                showFilter={true}
                onItemClick={handleAddCategory}
              />
            ) : (
              <FoodPicker
                containerData={response}
                showFilter={true}
                onItemClick={handleAddFood}
              />
            )}
          </div>
        </Drawer>
        <Panel bodyStyle={{ padding: 0, overflow: "hidden" }}>
          {isLoading && (
            <Loader style={{ position: "absolute", top: 10, left: 10 }} />
          )}
          {!!hasError && <CalloutComponent error={hasError} />}

          <MenuHeader data={response} onSuccess={() => arc.get()} />

          {response.type === "menu" && (
            <div style={{ width: "100%" }}>
              <MenuContents
                editButtonText={"Organiser et Modifier"}
                id={null}
                handleOrderNode={handleOrderNode}
                handleMode={handleMode}
                onClickDelete={handleDeleteNode}
                contents={response.contents}
              >
                <Placeholder onClick={() => handleMode("category")}>
                  <Add style={{ color: "#1989dd" }} />{" "}
                  <strong style={{ marginLeft: 10 }}>
                    Ajouter une section
                  </strong>
                </Placeholder>
                <Placeholder onClick={() => handleMode("food")}>
                  <Add style={{ color: "#1989dd" }} />{" "}
                  <strong style={{ marginLeft: 10 }}>Ajouter un plat</strong>
                </Placeholder>
              </MenuContents>
            </div>
          )}
        </Panel>

        <Panel>
          <BigButton
            style={{ color: "#E33", width: "100%" }}
            variant={"contained"}
            color="primary"
            onClick={handleDelete}
          >
            <Delete style={{ fontSize: 18, marginRight: 10 }} />
            Supprimer
          </BigButton>
        </Panel>
      </React.Fragment>
    );
  }
);

const MenuWithPage = (props) => {
  return (
    <MediumPage containerStyle={{ maxWidth: "100%" }}>
      <Menu {...props} />
    </MediumPage>
  );
};

export default MenuWithPage;
