import React, { useEffect, useRef } from "react";
import useFormState from "../../../hooks/useFormState";
import Field from "../../../../layout/inputs/Field";
import FieldWrapper from "../../../../layout/inputs/FieldWrapper";
import Button from "@mui/material/Button";
import { compose } from "redux";
import withToken from "../../../auth/HOC/withToken";
import { interpolate, withUseARC } from "react-arc";
import { configUserMenu } from "../arc/UserMenu";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import {
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
} from "@mui/material";
import EmojiLang from "../../shared/EmojiLang";
import LangText from "../../shared/LangText";

const BoardOptions = ({ handleSave, form, setForm }) => {
  const options_to_handle = [
    "meta_title",
    "meta_description",
    "meta_favicon",
    "meta_theme_color",
    "manifest_name",
    "manifest_short_name",
    "manifest_favicon",
    "manifest_icon_192",
    "manifest_icon_512",
    "manifest_theme_color",
    "manifest_background_color",
  ];
  const handleCheckBox = (e, v) => {
    setForm("options", {
      ...form.options,
      menu_toggle: v,
    });
  };
  const handleChanges = (name, v) => {
    setForm("options", {
      ...form.options,
      [name]: v,
    });
  };
  return (
    <React.Fragment>
      <hr />
      <div style={{ color: "#434343" }}>
        <p>Options Avancées</p>
        <div className="text-left">
          <FormControlLabel
            value={form.options.menu_toggle}
            labelPlacement={"start"}
            onChange={handleCheckBox}
            control={<Switch checked={form.options.menu_toggle} />}
            label="Menu dépliable"
          />
        </div>
      </div>

      {options_to_handle.map((option) => (
        <Field
          key={option}
          onEnterKey={handleSave}
          model={form.options}
          prop={option}
          onChange={handleChanges}
          placeholder={option}
        />
      ))}
    </React.Fragment>
  );
};

export const Edit = ({
  isBoard = false,
  duplicate,
  loaded,
  response,
  arc,
  onSuccess,
  type,
  title,
  description,
  lang,
  ...props
}) => {
  const [form, setForm, reset] = useFormState(
    response || { type, title, description, lang }
  );
  const handleSave = () => {
    const method = arc.arc.hasRequiredParams(props) ? "update" : "create";
    arc[method]({ body: form }).then(() => {
      if (onSuccess) onSuccess();
    });
  };

  const handleDuplicate = () => {
    //const id = duplicate
    const url = interpolate(arc.arc.config.paths.duplicate, { id: duplicate });
    return fetch(url, {
      method: "POST",
      headers: arc.arc.applyHeaders(arc.arc.config.headers, props),
      body: JSON.stringify({
        ...form,
        [isBoard ? "board_id" : "menu_id"]: duplicate,
      }),
    }).then(() => {
      if (onSuccess) onSuccess();
    });
    // console.log(url)
  };
  const resetHandler = useRef(reset);

  useEffect(() => {
    if (loaded) resetHandler.current(response);
  }, [loaded, resetHandler, response]);

  return (
    <React.Fragment>
      <Field
        onEnterKey={!duplicate ? handleSave : handleDuplicate}
        model={form}
        prop="title"
        onChange={setForm}
        placeholder="Titre"
      />
      <Field
        onEnterKey={!duplicate ? handleSave : handleDuplicate}
        model={form}
        prop="description"
        onChange={setForm}
        placeholder="Description"
      />

      <FieldWrapper>
        <InputLabel id="demo-multiple-name-label">Langue</InputLabel>
        <Select
          value={form.lang || "fr"}
          onChange={(event) => {
            setForm("lang", event.target.value);
          }}
          input={<OutlinedInput label="Langue" />}
          //MenuProps={MenuProps}
        >
          {["fr", "en", "es"].map((lang) => (
            <MenuItem
              key={lang}
              value={lang}
              //style={getStyles(name, personName, theme)}
            >
              <EmojiLang model={{ lang }} />
              <LangText style={{ marginLeft: 10 }} model={{ lang }} />
            </MenuItem>
          ))}
        </Select>
      </FieldWrapper>

      {isBoard && !!form.options && (
        <BoardOptions
          setForm={setForm}
          form={form}
          handleSave={!duplicate ? handleSave : handleDuplicate}
        />
      )}

      <FieldWrapper>
        <Button
          variant={"contained"}
          color="primary"
          onClick={!duplicate ? handleSave : handleDuplicate}
          style={{ display: "block", width: "100%" }}
        >
          Sauvegarder
        </Button>
      </FieldWrapper>
    </React.Fragment>
  );
};

export default compose(
  withToken,
  withUseARC(configUserMenu),
  withARCLoader
)(Edit);
