import React from "react";
import MediumPage from "../../../../layout/page/MediumPage";
import Panel from "../../../../layout/panel/Panel";
import withUser from "../../../auth/HOC/withUser";
import commons from "../commons";
import Foods from "./Foods";
import Welcome from "../../../home/Welcome";
import { Grid } from "@mui/material";
import Edit from "./Edit";

function FoodList({ user }) {
  const [picked, setPicked] = React.useState(null);
  const [refreshId, setRefreshId] = React.useState(null);
  const onPickedHandler = (food) => {
    setPicked(food.id);
  };
  const refreshHandler = () => {
    setRefreshId(Math.random() * 10000);
  };
  return (
    <MediumPage header={<Welcome />} containerStyle={{ maxWidth: "100%" }}>
      <h1 style={{ color: "#FFF" }}>Vos {commons.plural} </h1>
      <p style={{ color: "#FFF" }}>Liste de vos {commons.plural}</p>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Panel bodyStyle={{ padding: 0 }}>
            <Foods
              key={refreshId}
              showFilter={true}
              id={user.id}
              onItemClick={onPickedHandler}
            />
          </Panel>
        </Grid>
        <Grid item xs={12} sm={6}>
          {!!picked && (
            <Panel key={picked}>
              <Edit
                {...(picked ? { id: picked } : {})}
                onSuccess={refreshHandler}
                onDelete={() => {
                  setPicked(null);
                  refreshHandler();
                }}
              />
            </Panel>
          )}
        </Grid>
      </Grid>
    </MediumPage>
  );
}

export default withUser(FoodList);
