import { useFetch } from "use-http";
import { interpolate } from "react-arc";
import config from "../../../../config/config";

function useBoardAddBlocks({ token, data }) {
  const url = interpolate(config.boards.blocks, data);
  const { post, response, loading, error } = useFetch(url, {
    cacheLife: 0,
    cachePolicy: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  });

  const handleAdd = ({ id, type = "custom" }) => {
    return post({
      board_id: data.id,
      block_id: id,
      type: type,
    });
  };

  return [error, loading, response, handleAdd];
}

export default useBoardAddBlocks;
