import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {mixerConnector} from 'react-arc'
import createHOC from '../../../arc/createHOC'
import config from '../../../../config/config'
import ModelWithAuthContainer from '../../../auth/containers/ModelWithAuthContainer'
import {filterARCCONFIG} from '../../../arc/utils'
import React from 'react'

/** DONT FORGET TO :
 [ ] DECLARE YOUR store : config/store.js  -> userBoards
 [ ] ADD MODEL REQUIRED PROPS, in configUserBoards
 [ ] TYPE THEM  const types = {id: PropType.string.isRequired} (for instance
 [ ] IMPORT ARCModel, createHOC, config (for your urls), from where you are in the project (use your IDE)
 **/

/* ACTIONS */



export const USER_BOARDS_NAMESPACE = 'USER_BOARDS'

export const configUserBoards = {
    name: 'userBoards',
    uppercaseName: USER_BOARDS_NAMESPACE,
    modelProps: ['id'],
    collectionProps: [],
    paths: {
        item: config.boards.board
    },
    headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": ""
    },
    maxPendingRequestsPerReducer: 3,
    requestFetchDelay: 100
}


function create({ board, ARCConfig }) {
    return fetch(config.boards.board, {
        method: "post",
        headers: ARCConfig.headers,
        body: JSON.stringify(board)
    }).then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.json()
        }
        return response.json().then(r => Promise.reject(r))
    })
}

function update({ board, ARCConfig }) {
    return fetch(config.boards.board + '/' + board.id, {
        method: "put",
        headers: ARCConfig.headers,
        body: JSON.stringify(board)
    }).then(r => r.json())
}

function remove({ board, ARCConfig }) {
    return fetch(config.boards.board + '/' + board.id, {
        method: "delete",
        headers: ARCConfig.headers
    }).then(r => r.json())
}

const types = {
    id: PropTypes.string.isRequired
}

export class UserBoardsContainerBase extends ModelWithAuthContainer {

    static defaultProps = {
        ARCConfig: configUserBoards,
    }

    static propTypes = types

    create = (menu) => create({ARCConfig: this.ARCConfig, menu: menu})

    update = (menu) => update({ARCConfig: this.ARCConfig, menu: menu})

    delete = (menu) => remove({ARCConfig: this.ARCConfig, menu: menu})

    render() {
        const Component = this.props.component
        return <Component {...filterARCCONFIG(this.props)}
                          error={this.getError()}
                          isNew={this.isNew()}
                          loaded={this.isLoaded()}
                          update={this.update}
                          delete={this.delete}
                          create={this.create}
                          fetch={()=>this.fetch(this.getParams())}
                          model={this.isLoaded() ? this.getModel() : null}/>
    }

}

const connector = mixerConnector(connect, configUserBoards)
export const UserBoardsContainer = connector(UserBoardsContainerBase)

const withUserBoards = createHOC({
    Container: UserBoardsContainer,
    ARCConfig: configUserBoards
})
withUserBoards.propTypes = types

withUserBoards.defaultProps = {}

export default withUserBoards