import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Avatar,
} from "@mui/material";
import navigate from "../../singletons/navigate";
import { Menu as MenuIcon, AccountCircle } from "@mui/icons-material";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import connector from "../../components/auth/connector";
import "./header.scss";
import { DialogConnect } from "../../components/auth/LdapComponent";
import AbstractAuthComponent from "../../components/auth/components/AbstractAuthComponent";
import { compose } from "redux";
import withUser from "../../components/auth/HOC/withUser";
import withToken from "../../components/auth/HOC/withToken";
import withProfile from "../../components/user/HOC/withPofile";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: "#FFF",
    color: "#434343",
  },

  button: {
    color: "#FFF",
    display: "inline-block",
  },
  avatar: {
    margin: 0,
    width: 24,
    height: 24,
  },
});

const LANG = {
  profile: "Compte",
  signout: "Se déconnecter",
  signin: "Se connecter",
  signup: "Créer un compte",
};

const HeaderMenu = connect(connector)(
  class extends AbstractAuthComponent {
    toggleAuth = (status) =>
      this.setState({
        authVisible:
          typeof status === "boolean" ? status : !this.state.authVisible,
      });

    toggleSignin = (status) =>
      this.setState({
        authVisible:
          typeof status === "boolean" ? status : !this.state.authVisible,
      });

    constructor() {
      super();
      this.state = {
        authVisible: false,
      };
    }

    render() {
      const { anchorEl, open, handleClose } = this.props;
      return (
        <div>
          <Menu
            key={"menu"}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            {this.isLogged()
              ? [
                  <MenuItem
                    key={"profile"}
                    onClick={() => {
                      navigate("/settings/profile");
                      handleClose();
                    }}
                  >
                    {LANG.profile}
                  </MenuItem>,
                  <MenuItem
                    key={"logout"}
                    onClick={() => {
                      this.disconnect();
                      handleClose();
                      navigate("/");
                    }}
                  >
                    {LANG.signout}
                  </MenuItem>,
                ]
              : [
                  <MenuItem
                    key="sign-in"
                    onClick={() => {
                      handleClose();
                      this.toggleAuth(true);
                    }}
                  >
                    {LANG.signin}
                  </MenuItem>,
                  <Divider key="divider" />,
                  <MenuItem
                    key="sign-up"
                    onClick={() => {
                      handleClose();
                      navigate("/sign-up");
                    }}
                  >
                    {LANG.signup}
                  </MenuItem>,
                ]}
          </Menu>
          <DialogConnect
            open={this.state.authVisible}
            close={() => this.toggleAuth(false)}
          />
        </div>
      );
    }
  }
);

const withUserId = (Wrapped) => (props) =>
  <Wrapped {...props} id={props.userId} />;

const HeaderComponent = ({ classes, profile, isLogged, toggler }) => {
  const [state, setState] = useState({
    anchorEl: null,
    auth: true,
    authVisible: false,
  });

  const handleMenu = (event) => {
    setState({ ...state, anchorEl: event.currentTarget });
  };
  const handleClose = () => {
    setState({ ...state, anchorEl: null });
  };
  const handleSideBar = () => toggler();

  const { auth, anchorEl } = state;
  const open = Boolean(anchorEl);

  return (
    <AppBar position={"fixed"} className={classes.appBar}>
      <Toolbar>
        <IconButton
          onClick={() => handleSideBar()}
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.flex}
          style={{
            fontFamily: "Baloo Bhaina 2",
            fontWeight: 700,
            cursor: "pointer",
            textTransform: "none",
          }}
          onClick={() => navigate("/")}
        >
          Menu-Go
        </Typography>
        {auth && (
          <div>
            {isLogged ? profile.first_name : "Connexion"}
            <IconButton
              aria-owns={open ? "menu-appbar" : null}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {isLogged ? (
                <Avatar
                  alt={profile.first_name}
                  className={classes.avatar}
                  src={profile.avatar}
                />
              ) : (
                <AccountCircle />
              )}
            </IconButton>
            <HeaderMenu
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
            />
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

HeaderComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withUser,
  withToken,
  withUserId,
  withProfile
)(HeaderComponent);
