import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mixerConnector } from "react-arc";
import ModelWithAuthContainer from "../../../auth/containers/ModelWithAuthContainer";
import createHOC from "../../../arc/createHOC";
import config from "../../../../config/config";
import { filterARCCONFIG } from "../../../arc/utils";
import React from "react";

export const USER_BOARD_NAMESPACE = "USER_BOARD";

export const configUserBoard = {
  name: "userBoard",
  uppercaseName: USER_BOARD_NAMESPACE,
  modelProps: ["id"],
  collectionProps: [],
  paths: {
    item: config.boards.board + "/{id}",
    create: config.boards.board,
    duplicate: config.boards.duplicate,
  },
  headers: {
    "Content-Type": "application/json",
    "X-Auth-Token": "{token}",
  },
  fetchOnce: true,
  maxPendingRequestsPerReducer: 3,
  requestFetchDelay: 100,
};

const types = {
  id: PropTypes.string.isRequired,
};

export class UserBoardContainerBase extends ModelWithAuthContainer {
  static defaultProps = {
    ARCConfig: configUserBoard,
  };

  static propTypes = types;

  render() {
    const Component = this.props.component;
    return (
      <Component
        {...filterARCCONFIG(this.props)}
        error={this.getError()}
        isNew={this.isNew()}
        loaded={this.isLoaded()}
        //update={this.update}
        remove={this.remove}
        //create={this.create}
        fetch={() => this.fetch(this.getParams())}
        model={this.isLoaded() ? this.getModel() : null}
      />
    );
  }
}

const connector = mixerConnector(connect, configUserBoard);
export const UserBoardContainer = connector(UserBoardContainerBase);

const withUserBoard = createHOC({
  Container: UserBoardContainer,
  ARCConfig: configUserBoard,
});
withUserBoard.propTypes = types;

withUserBoard.defaultProps = {};

export default withUserBoard;
