import React from "react";
import navigate from "../singletons/navigate";
import { Home } from "@mui/icons-material";
import withUser from "../components/auth/HOC/withUser";

function FatButton(props) {
  const { toggler } = props;
  return (
    <button
      className="fat-button"
      onClick={() => {
        toggler(false);
        navigate(props.to);
      }}
    >
      <div className="icon">{props.icon}</div>
      <div className="title">{props.title}</div>
    </button>
  );
}

const styles = {
  icon: {
    verticalAlign: "middle",
  },
};

export function SidebarMenu({ user, ...props }) {
  return (
    <div>
      <FatButton
        {...props}
        title="Accueil"
        to="/"
        icon={<Home style={styles.icon} />}
      />
      {!!user && (
        <React.Fragment>
          <FatButton
            {...props}
            title="Vos Cartes"
            to="/boards"
            icon={<Home style={styles.icon} />}
          />
          <FatButton
            {...props}
            title="Vos Menus"
            to="/menus"
            icon={<Home style={styles.icon} />}
          />
          <FatButton
            {...props}
            title="Vos Séparateurs"
            to="/menus-titles"
            icon={<Home style={styles.icon} />}
          />
          <FatButton
            {...props}
            title="Vos Catégories"
            to="/categories"
            icon={<Home style={styles.icon} />}
          />
          <FatButton
            {...props}
            title="Vos Plats"
            to="/foods"
            icon={<Home style={styles.icon} />}
          />
          <FatButton
            {...props}
            title="Vos Personnalisations"
            to="/blocks"
            icon={<Home style={styles.icon} />}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export default withUser(SidebarMenu);
