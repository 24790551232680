import React from "react";
import withUser from "../../../auth/HOC/withUser";
import Blocks from "../../blocks/components/Blocks";

function BlockPicker({ user, onItemClick, showDefaultMenu }) {
  return (
    <Blocks
      showDefaultMenu={showDefaultMenu}
      showCreate={false}
      id={user.id}
      onItemClick={onItemClick}
    />
  );
}
export default withUser(BlockPicker);
