import React from "react";
//import PropTypes from 'prop-types'
import { Divider } from "@mui/material";
import Edit from "./Edit";
import Dialog from "@mui/material/Dialog";

function EditCategoryDialog({ id, open, onClose, onSuccess }) {
  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
      <div style={{ padding: "20px 40px" }}>
        <h4>Éditer</h4>
      </div>
      <Divider />
      <div style={{ minHeight: 200 }}>
        <div style={{ padding: "20px 40px" }}>
          <Edit {...(id ? { id } : {})} onSuccess={onSuccess} />
        </div>
      </div>
    </Dialog>
  );
}

export default EditCategoryDialog;
