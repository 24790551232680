import React from 'react'
// import PropTypes from 'prop-types'

const styles = {
    placeholder: {
        borderRadius: 4,
        //border: '1px dashed #DEDEED',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: 60,
        margin: 5,
        cursor: 'pointer',
        backgroundColor: '#FFF',
        border: '2px dashed #434343',
        marginTop: 10
        //boxShadow: '0 1px 3px rgba(0,0,0,.3)'
    }
}

const Placeholder =({onClick, children, style, className}) => {
    return <div  className={className + (onClick ? ' is-clickable' : '')} onClick={onClick} style={{...styles.placeholder, ...style}}>
        {children}
    </div>
}

Placeholder.propTypes = {}
Placeholder.defaultProps = {}

export default Placeholder
