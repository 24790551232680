import React from "react";
import CategoryList from "./components/CategoryList";
import withUserCheck from "../../auth/HOC/withUserCheck";
import { compose } from "redux";
import withUser from "../../auth/HOC/withUser";
import Category from "./components/Category";

const CategoryPage = (props) => {
  const id = props.match.params.id;
  return id ? <Category id={id} /> : <CategoryList />;
};

CategoryPage.propTypes = {};
CategoryPage.defaultProps = {};

export default compose(withUser, withUserCheck)(CategoryPage);
