import React from "react";
import { Add, RemoveCircle } from "@mui/icons-material";
import MenuContents from "./MenuContents";
import Placeholder from "./Placeholder";
import IconButton from "@mui/material/IconButton";

const styles = {
  titleSection: {
    //margin: '10px',
    padding: "10px",
    //backgroundColor: '#F0F0F0',
    //textAlign: 'center',
    //border: '1px solid #F0F0F0',
    //borderRadius: 3
  },
  title: {
    fontSize: 16,
    textAlign: "center",
  },
  remove: {},
  icon: {
    color: "#E33",
  },
};

const CategoryTitle = ({ category }) => {
  const { title, description } = category;
  return (
    <div style={styles.title}>
      <strong>{title}</strong>
      <br />
      <span className="text-muted">{description}</span>
    </div>
  );
};

const CategoryItem = ({
  item,
  onClickDelete,
  handleMode,
  handleOrderNode,
  editing = false,
}) => {
  const { id, title } = item;
  const button = (
    <Placeholder
      className="animated fadeIn"
      style={{}}
      onClick={() => handleMode("food", id)}
    >
      <Add style={{ color: "#1989dd" }} />{" "}
      <strong style={{ marginLeft: 10 }}>Ajouter un plat</strong>
    </Placeholder>
  );
  return (
    <div className={"menu-category"} style={styles.titleSection}>
      <CategoryTitle category={item} />

      {editing && (
        <div
          className="animated fadeIn menu-offset-button"
          style={styles.remove}
        >
          <IconButton size={"small"} onClick={() => onClickDelete(item)}>
            <RemoveCircle style={styles.icon} />
          </IconButton>
        </div>
      )}

      <MenuContents
        editButtonText={"Modifier le contenu " + title}
        handleOrderNode={handleOrderNode}
        id={item.id}
        editing={editing}
        contents={item.contents}
        onClickDelete={onClickDelete}
        handleMode={handleMode}
      >
        {button}
      </MenuContents>
    </div>
  );
};

CategoryItem.propTypes = {};
CategoryItem.defaultProps = {};

export default CategoryItem;
