import React from "react";
import Page from "../../../layout/page/Page";
import MenuList from "./components/MenuList";
import withUserCheck from "../../auth/HOC/withUserCheck";
import withUser from "../../auth/HOC/withUser";
import Menu from "./components/Menu";

const MenuTitlePage = (props) => {
  const menuId = props.match.params.id;
  return (
    <Page className="full-width">
      {menuId ? <Menu id={menuId} /> : <MenuList type={"title"} />}
    </Page>
  );
};

export default withUser(withUserCheck(MenuTitlePage));
