const config = {
  images: process.env.PUBLIC_URL,
  login: process.env.PUBLIC_URL + "/api/login",
  signup: process.env.PUBLIC_URL + "/api/sign-up",
  profile: process.env.PUBLIC_URL + "/api/user/profile",
  desk: "https://menu-go.sunny.fr/{userName}/{boardId}",
  user: {
    teams: process.env.PUBLIC_URL + "/api/user/teams",
  },
  menus: {
    menu: process.env.PUBLIC_URL + "/api/user/menu",
    contents: process.env.PUBLIC_URL + "/api/user/menu/{id}/contents",
    duplicate: process.env.PUBLIC_URL + "/api/user/menu/{id}/duplicate",
  },
  categories: {
    category: process.env.PUBLIC_URL + "/api/user/category",
  },
  boards: {
    board: process.env.PUBLIC_URL + "/api/user/board",
    contents: process.env.PUBLIC_URL + "/api/user/board/{id}/contents",
    blocks: process.env.PUBLIC_URL + "/api/user/board/{id}/blocks",
    publish: process.env.PUBLIC_URL + "/api/user/board/{id}/publish",
    duplicate: process.env.PUBLIC_URL + "/api/user/board/{id}/duplicate",
  },
  foods: {
    food: process.env.PUBLIC_URL + "/api/user/food",
    duplicate: process.env.PUBLIC_URL + "/api/user/food/{id}/duplicate",
  },
  blocks: {
    block: process.env.PUBLIC_URL + "/api/user/block",
  },
  logout: process.env.PUBLIC_URL + "/api/logout",
  upload: process.env.PUBLIC_URL + "/api/upload",
};

export default config;
