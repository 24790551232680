import React, { useState } from "react";
//import PropTypes from 'prop-types'
import { Grid, List } from "@mui/material";
import MediumPage from "../../../../layout/page/MediumPage";
import Panel from "../../../../layout/panel/Panel";
import Button from "@mui/material/Button";
import useToggle from "../../../hooks/useToggle";
import withUserMenus from "../arc/userMenus";
import { compose } from "redux";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import withUser from "../../../auth/HOC/withUser";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import withToken from "../../../auth/HOC/withToken";
import navigate from "../../../../singletons/navigate";
import EditMenuDialog from "./EditMenuDialog";
import { commons, commons_menu_title } from "../commons";
import Welcome from "../../../home/Welcome";
import colorHash from "../../../../layout/colors/colorHash";

import { Add } from "@mui/icons-material";
import EmojiLang from "../../shared/EmojiLang";
import { Menu } from "./Menu";

function getDuplicateData(model) {
  if (!model) return {};
  const { title, description, lang } = model;
  return {
    title,
    description,
    lang,
  };
}

export const Menus = compose(
  withToken,
  withUserMenus,
  withARCLoader
)(
  ({
    model,
    type = "menu",
    fetch,
    onItemClick,
    size = 200,
    showCreate = true,
  }) => {
    const [visible, setVisible] = useToggle(false);
    const [duplicateId, setDuplicateId] = useState(null);
    const onSuccess = () => {
      fetch();
      setVisible(false);
      setDuplicateId(null);
    };
    const comm = type === "menu" ? commons : commons_menu_title;

    return (
      <div>
        <EditMenuDialog
          duplicate={duplicateId}
          duplicateData={getDuplicateData(
            model.find((item) => item.id === duplicateId)
          )}
          type={type}
          open={visible}
          onClose={() => {
            setVisible(false);
            setDuplicateId(null);
          }}
          onSuccess={onSuccess}
        />
        <List className="menu-go-list">
          {model
            .filter((p) => p.type === type)
            .slice(0, size)
            .map((item) => (
              <ListItem
                className="menu-go-list-item"
                key={item.id}
                button
                secondaryAction={
                  showCreate ? (
                    <Button
                      onClick={() => {
                        setDuplicateId(item.id);
                        setVisible(true);
                      }}
                    >
                      Dupliquer <Add />
                    </Button>
                  ) : undefined
                }
              >
                <ListItemAvatar
                  onClick={() =>
                    onItemClick
                      ? onItemClick(item)
                      : navigate(comm.basePath + "/" + item.id)
                  }
                >
                  <EmojiLang model={item} />
                </ListItemAvatar>
                <ListItemAvatar
                  onClick={() =>
                    onItemClick
                      ? onItemClick(item)
                      : navigate(comm.basePath + "/" + item.id)
                  }
                >
                  <Avatar
                    style={{ backgroundColor: colorHash.hex(item.title) }}
                  >
                    {item.title?.[0] || ""}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  onClick={() =>
                    onItemClick
                      ? onItemClick(item)
                      : navigate(comm.basePath + "/" + item.id)
                  }
                  primary={<strong>{item.title}</strong>}
                  secondary={item.description?.substr(0, 50)}
                />
              </ListItem>
            ))}
        </List>
        {showCreate && (
          <Button
            onClick={() => setVisible(true)}
            style={{ display: "block", width: "100%" }}
          >
            Créer un nouveau {comm.singular}
          </Button>
        )}
      </div>
    );
  }
);

function MenuList({ user, type = "menu" }) {
  //const [item, setItem] = useItemPicker(null)
  const [picked, setPicked] = React.useState(null);
  const [refreshId, setRefreshId] = React.useState(null);
  const onPickedHandler = (food) => {
    setPicked(food.id);
  };
  const refreshHandler = () => {
    setRefreshId(Math.random() * 10000);
  };

  const comm = type === "menu" ? commons : commons_menu_title;
  return (
    <MediumPage header={<Welcome />} containerStyle={{ maxWidth: "100%" }}>
      <h1 style={{ color: "#FFF" }}>Vos {comm.plural}</h1>
      <p style={{ color: "#FFF" }}>Liste de vos {comm.plural}</p>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Panel bodyStyle={{ padding: 0 }}>
            <Menus
              type={type}
              id={user.id}
              key={refreshId}
              onItemClick={onPickedHandler}
            />
          </Panel>
        </Grid>
        <Grid item xs={12} sm={6}>
          {!!picked && (
            <Panel key={picked}>
              <Menu
                {...(picked ? { id: picked, type } : { type })}
                onSuccess={refreshHandler}
                onDelete={() => {
                  setPicked(null);
                  refreshHandler();
                }}
              />
            </Panel>
          )}
        </Grid>
      </Grid>
    </MediumPage>
  );
}

export default withUser(MenuList);
