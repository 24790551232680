import React, { useEffect, useRef } from "react";
import useFormState from "../../../hooks/useFormState";
import Field from "../../../../layout/inputs/Field";
import FieldWrapper from "../../../../layout/inputs/FieldWrapper";
import Button from "@mui/material/Button";
import { compose } from "redux";
import withToken from "../../../auth/HOC/withToken";
import { interpolate, withUseARC } from "react-arc";
import { configUserFood } from "../arc/UserFood";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import {
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import EmojiLang from "../../shared/EmojiLang";
import LangText from "../../shared/LangText";
import navigate from "../../../../singletons/navigate";
import withConfirmDialog from "../../../../layout/confirm/withConfirmDialog";

const Edit = ({
  loaded,
  response,
  arc,
  onSuccess,
  duplicate,
  title,
  description,
  lang,
  price,
  price_icon,
  price_desc,
  price_2,
  price_icon_2,
  price_desc_2,
  setConfirm,
  onDelete,
  ...props
}) => {
  const [form, setForm, reset] = useFormState(
    response || {
      title,
      description,
      lang,
      price,
      price_icon,
      price_desc,
      price_2,
      price_icon_2,
      price_desc_2,
    }
  );

  const handleDelete = () => {
    setConfirm({
      title: "Merci de confirmer",
      labelAccept: "Supprimer",
      labelReject: "Annuler",
      callback: () =>
        arc.remove().then(() => {
          if (onDelete) onDelete();
          navigate("/foods");
        }),
    });
  };

  const handleSave = () => {
    const method = arc.arc.hasRequiredParams(props) ? "update" : "create";
    arc[method]({ body: form }).then(() => {
      if (onSuccess) onSuccess();
    });
  };

  const handleDuplicate = () => {
    //const id = duplicate
    const url = interpolate(arc.arc.config.paths.duplicate, { id: duplicate });
    return fetch(url, {
      method: "POST",
      headers: arc.arc.applyHeaders(arc.arc.config.headers, props),
      body: JSON.stringify({ ...form, food_id: duplicate }),
    }).then(() => {
      if (onSuccess) onSuccess();
    });
    // console.log(url)
  };

  const resetHandler = useRef(reset);

  useEffect(() => {
    if (loaded) resetHandler.current(response);
  }, [loaded, response, resetHandler]);

  return (
    <React.Fragment>
      <Field
        onEnterKey={!duplicate ? handleSave : handleDuplicate}
        model={form}
        prop="title"
        onChange={setForm}
        placeholder="Titre"
      />
      <Field
        onEnterKey={!duplicate ? handleSave : handleDuplicate}
        model={form}
        prop="description"
        onChange={setForm}
        placeholder="Description"
      />

      <FieldWrapper>
        <InputLabel id="demo-multiple-name-label">Langue</InputLabel>
        <Select
          value={form.lang || "fr"}
          onChange={(event) => {
            setForm("lang", event.target.value);
          }}
          input={<OutlinedInput label="Langue" />}
          //MenuProps={MenuProps}
        >
          {["fr", "en", "es"].map((lang) => (
            <MenuItem
              key={lang}
              value={lang}
              //style={getStyles(name, personName, theme)}
            >
              <EmojiLang model={{ lang }} />
              <LangText style={{ marginLeft: 10 }} model={{ lang }} />
            </MenuItem>
          ))}
        </Select>
      </FieldWrapper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Field
            onEnterKey={!duplicate ? handleSave : handleDuplicate}
            model={form}
            prop="price"
            onChange={setForm}
            placeholder="Prix"
          />
          <Field
            onEnterKey={!duplicate ? handleSave : handleDuplicate}
            model={form}
            prop="price_desc"
            onChange={setForm}
            placeholder="Prix Description"
          />
          <Field
            onEnterKey={!duplicate ? handleSave : handleDuplicate}
            model={form}
            prop="price_icon"
            onChange={setForm}
            placeholder="Prix Icone"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            onEnterKey={!duplicate ? handleSave : handleDuplicate}
            model={form}
            prop="price_2"
            onChange={setForm}
            placeholder="Prix"
          />
          <Field
            onEnterKey={!duplicate ? handleSave : handleDuplicate}
            model={form}
            prop="price_desc_2"
            onChange={setForm}
            placeholder="Prix Description"
          />
          <Field
            onEnterKey={!duplicate ? handleSave : handleDuplicate}
            model={form}
            prop="price_icon_2"
            onChange={setForm}
            placeholder="Prix Icone"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            onEnterKey={!duplicate ? handleSave : handleDuplicate}
            model={form}
            prop="price_3"
            onChange={setForm}
            placeholder="Prix"
          />
          <Field
            onEnterKey={!duplicate ? handleSave : handleDuplicate}
            model={form}
            prop="price_desc_3"
            onChange={setForm}
            placeholder="Prix Description"
          />
          <Field
            onEnterKey={!duplicate ? handleSave : handleDuplicate}
            model={form}
            prop="price_icon_3"
            onChange={setForm}
            placeholder="Prix Icone"
          />
        </Grid>
      </Grid>

      <FieldWrapper>
        <Button
          variant={"contained"}
          color="primary"
          onClick={!duplicate ? handleSave : handleDuplicate}
          style={{ display: "block", width: "100%" }}
        >
          Sauvegarder
        </Button>
      </FieldWrapper>
      <FieldWrapper>
        <Button
          variant={"contained"}
          color={"warning"}
          onClick={handleDelete}
          style={{ display: "block", width: "100%" }}
        >
          Supprimer
        </Button>
      </FieldWrapper>
    </React.Fragment>
  );
};

export default compose(
  withToken,
  withUseARC(configUserFood),
  withARCLoader,
  withConfirmDialog
)(Edit);
