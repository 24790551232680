import React from "react";
import PropTypes from "prop-types";
import FieldWrapper from "./FieldWrapper";
import FieldComponent from "./FieldComponent";

const Field = ({ model, prop, placeholder, onChange, onEnterKey, type }) => {
  return (
    <FieldWrapper>
      <FieldComponent
        type={type}
        onEnterKey={type === "textarea" ? null : onEnterKey}
        disabled={!onChange}
        variant={"outlined"}
        name={prop}
        placeholder={placeholder}
        onChange={onChange}
        value={model[prop] || ""}
      />
    </FieldWrapper>
  );
};
Field.propTypes = {
  model: PropTypes.object,
  prop: PropTypes.string,
  placeholder: PropTypes.string,
  changeProp: PropTypes.func,
};

export default Field;
