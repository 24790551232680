import React from 'react'
import Categories from '../../categories/components/Categories'
import withUser from '../../../auth/HOC/withUser'
//import PropTypes from 'prop-types'
//import CategoryList from '../../categories/components/CategoryList'

function CategoryPicker({user, onItemClick, showFilter, containerData}) {
    return (
        <Categories containerData={containerData} showFilter={showFilter} id={user.id} onItemClick={onItemClick}/>
    )
}

CategoryPicker.propTypes = {}
CategoryPicker.defaultProps = {}

export default withUser(CategoryPicker)
