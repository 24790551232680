import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mixerConnector } from "react-arc";
import ModelWithAuthContainer from "../../../auth/containers/ModelWithAuthContainer";
import createHOC from "../../../arc/createHOC";
import config from "../../../../config/config";
import { filterARCCONFIG } from "../../../arc/utils";
import React from "react";

export const USER_MENU_NAMESPACE = "USER_MENU";

export const configUserMenu = {
  name: "userMenu",
  uppercaseName: USER_MENU_NAMESPACE,
  modelProps: ["id"],
  collectionProps: [],
  paths: {
    item: config.menus.menu + "/{id}",
    create: config.menus.menu,
    duplicate: config.menus.duplicate,
  },
  headers: {
    "Content-Type": "application/json",
    "X-Auth-Token": "{token}",
  },
  fetchOnce: true,
  maxPendingRequestsPerReducer: 3,
  requestFetchDelay: 100,
};

const types = {
  id: PropTypes.string.isRequired,
};

export class UserMenuContainerBase extends ModelWithAuthContainer {
  static defaultProps = {
    ARCConfig: configUserMenu,
  };

  static propTypes = types;

  render() {
    const Component = this.props.component;
    return (
      <Component
        {...filterARCCONFIG(this.props)}
        error={this.getError()}
        isNew={this.isNew()}
        loaded={this.isLoaded()}
        //update={this.update}
        remove={this.remove}
        //create={this.create}
        fetch={() => this.fetch(this.getParams())}
        model={this.isLoaded() ? this.getModel() : null}
      />
    );
  }
}

const connector = mixerConnector(connect, configUserMenu);
export const UserMenuContainer = connector(UserMenuContainerBase);

const withUserMenu = createHOC({
  Container: UserMenuContainer,
  ARCConfig: configUserMenu,
});
withUserMenu.propTypes = types;

withUserMenu.defaultProps = {};

export default withUserMenu;
