import React, { useEffect, useRef } from "react";
import useFormState from "../../../hooks/useFormState";
import Field from "../../../../layout/inputs/Field";
import FieldWrapper from "../../../../layout/inputs/FieldWrapper";
import Button from "@mui/material/Button";
import { compose } from "redux";
import withToken from "../../../auth/HOC/withToken";
import { withUseARC } from "react-arc";
import { configUserCategory } from "../arc/UserCategory";
import withARCLoader from "../../../auth/HOC/withARCLoader";

const Edit = ({ loaded, response, arc, onSuccess, ...props }) => {
  const [form, setForm, reset] = useFormState(response || {});
  const handleSave = () => {
    const method = arc.arc.hasRequiredParams(props) ? "update" : "create";

    arc[method]({ body: form }).then(() => {
      if (onSuccess) onSuccess();
    });
  };

  const resetHandler = useRef(reset);

  useEffect(() => {
    if (loaded) resetHandler.current(response);
  }, [loaded, response, resetHandler]);

  return (
    <React.Fragment>
      <Field
        onEnterKey={handleSave}
        model={form}
        prop="title"
        onChange={setForm}
        placeholder="Titre"
      />
      <Field
        onEnterKey={handleSave}
        model={form}
        prop="description"
        onChange={setForm}
        placeholder="Description"
      />

      <FieldWrapper>
        <Button
          variant={"contained"}
          color="primary"
          onClick={handleSave}
          style={{ display: "block", width: "100%" }}
        >
          Sauvegarder
        </Button>
      </FieldWrapper>
    </React.Fragment>
  );
};

Edit.propTypes = {};
Edit.defaultProps = {};

export default compose(
  withToken,
  withUseARC(configUserCategory),
  withARCLoader
)(Edit);
