import { withStyles } from "@mui/styles";

const styles = {
  button: {
    root: {
      display: "block",
      width: "100%",
    },
  },
};
export const withLoginComponentStyles = withStyles(styles);
