import React  from "react"
import { Route } from "react-router"
import CategoryPage from './CategoryPage'

const RouterCategories = () => {
  return (
      <Route exact path="/categories/:id?" name="Categories" component={CategoryPage}/>
  )
}

RouterCategories.propTypes = {}
RouterCategories.defaultProps = {}

export default RouterCategories
