import React from "react";
import Panel from "../../../../layout/panel/Panel";
import MediumPage from "../../../../layout/page/MediumPage";
import withToken from "../../../auth/HOC/withToken";
import { compose } from "redux";
import { Button } from "@mui/material";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import { withUseARC } from "react-arc";
import navigate from "../../../../singletons/navigate";
import { Column, Row } from "../../../../layout/grid";
import commons from "../commons";
import { configUserCategory } from "../arc/UserCategory";
import useToggle from "../../../hooks/useToggle";
import EditCategoryDialog from "./EditCategoryDialog";

function Category({ response, arc }) {
  const [visible, setVisible] = useToggle(false);

  const handleDelete = () =>
    arc.remove().then(() => navigate(commons.basePath));
  // const handleOther = () => {
  //     console.log('hello')
  // }
  const handleEdit = () => setVisible(true);
  const closeHandler = () => setVisible(false);

  return (
    <MediumPage containerStyle={{ maxWidth: "100%" }}>
      <EditCategoryDialog
        id={response.id}
        open={visible}
        onClose={closeHandler}
        onSuccess={() => {
          closeHandler();
          arc.get();
        }}
      />
      <Row>
        <Column size={6}>
          <Panel>
            <div className="text-center">
              <h4>{response.title}</h4>
              <p>{response.description}</p>
            </div>
          </Panel>
        </Column>
        <Column size={6}>
          <Panel>
            <div style={{ padding: "20px 40px" }}>
              <Button
                style={{ width: "100%" }}
                variant={"contained"}
                color="primary"
                onClick={handleEdit}
              >
                Modifier
              </Button>
            </div>
            <div style={{ padding: "20px 40px" }}>
              <Button
                style={{ backgroundColor: "#E33", width: "100%" }}
                variant={"contained"}
                color="primary"
                onClick={handleDelete}
              >
                Supprimer
              </Button>
            </div>
          </Panel>
        </Column>
      </Row>
    </MediumPage>
  );
}

Category.propTypes = {};
Category.defaultProps = {};

export default compose(
  withToken,
  withUseARC(configUserCategory),
  withARCLoader
)(Category);
