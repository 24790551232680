import { compose } from "redux";
import withToken from "../../../auth/HOC/withToken";
import { withUseARC } from "react-arc";
import { configUserBoard } from "../arc/UserBoard";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import { Edit } from "../../menus/components/Edit";

export default compose(
  withToken,
  withUseARC(configUserBoard),
  withARCLoader
)(Edit);
