import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
//import { routerReducer, routerMiddleware } from "react-router-redux"

import { connectRouter, routerMiddleware } from "connected-react-router";

import history from "./history";
import auth from "../components/auth/reducers/auth";
import appClient from "../components/client/reducers/appClient";
import { toaster } from "re-toaster";

import { mixerStore } from "react-arc";
import fieldState from "../layout/px-status/reducers/fieldState";
import signupConfig from "../components/auth/config/signupConfig";
import { profileConfig } from "../components/user/config/profileConfig";
import { configUserMenus } from "../components/editor/menus/arc/userMenus";
import { configUserMenu } from "../components/editor/menus/arc/UserMenu";
import { configUserCategory } from "../components/editor/categories/arc/UserCategory";
import { configUserFood } from "../components/editor/foods/arc/UserFood";
import { configUserBoard } from "../components/editor/boards/arc/UserBoard";
import { configUserBoards } from "../components/editor/boards/arc/userMenus";
import { configUserBlock } from "../components/editor/blocks/arc/UserBlock";

const middleware = applyMiddleware(promise, thunk, routerMiddleware(history));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(middleware);

const reducers = {
  toaster,
  appClient,
  auth,
  fieldState,
  signup: mixerStore({ config: signupConfig }),
  profile: mixerStore({ config: profileConfig }),
  userMenus: mixerStore({ config: configUserMenus }),
  userMenu: mixerStore({ config: configUserMenu }),
  userFood: mixerStore({ config: configUserFood }),
  userBlock: mixerStore({ config: configUserBlock }),
  userCategory: mixerStore({ config: configUserCategory }),
  userBoards: mixerStore({ config: configUserBoards }),
  userBoard: mixerStore({ config: configUserBoard }),
  router: connectRouter(history),
};

const store =
  Object.keys(reducers).length > 0
    ? createStore(combineReducers(reducers), enhancer)
    : createStore(enhancer);

export default store;
