import React from "react";
import Abstract from "../AbstractAuthComponent";
import { authDirectToken } from "../../actions/authActions";
import { connect } from "react-redux";
import { LoginComponent } from "../../../../layout/login/LoginComponent";
import "../login.scss";

import connector from "../../connector";
import { withLoginComponentStyles } from "../../../../layout/login/withLoginComponentStyles";

const Login = withLoginComponentStyles(
  class LdapLoginComponent extends LoginComponent {
    submit = () => {
      if (this.props.onChange)
        this.props.onChange(this.state.user.name, this.state.user.password);
    };
  }
);

class LoginConnectComponent extends Abstract {
  login = (username, password) => {
    this.props.dispatch(authDirectToken({ username, password }));
  };

  render() {
    const error =
      this.props.user?.error?.response?.data?.message || this.props.user.error;
    return (
      <div className="auth-wrapper" style={this.props.style}>
        {this.props.children}

        <Login
          style={this.props.loginStyle}
          error={error}
          name={this.props.user.username}
          password={this.props.user.password}
          pending={this.props.user.pending}
          onChange={this.login}
        />
      </div>
    );
  }

  componentDidUpdate() {
    if (this.props.onChange)
      this.props.onChange(this.props.user.credentialValid);
  }
}

export default connect(connector)(LoginConnectComponent);
