import React from "react";
import withUser from "../../../auth/HOC/withUser";
import { Menus } from "../../menus/components/MenuList";

function MenuPicker({ user, type, onItemClick }) {
  return (
    <Menus
      showCreate={false}
      type={type}
      id={user.id}
      onItemClick={onItemClick}
    />
  );
}

MenuPicker.propTypes = {};
MenuPicker.defaultProps = {};

export default withUser(MenuPicker);
