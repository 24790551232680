import React from "react";
import { Jumbo } from "../../layout/page/Jumbo";
//import PropTypes from "prop-types"
// import { Button } from "@mui/material"
// import { Assistant, CloudDownload } from "@mui/icons-material"
// import navigate from "../../singletons/navigate"
// import { Column, Row } from "../../layout/grid"

const Welcome = () => (
  <Jumbo>
    <div className="jumbotron-home">
      <div className="container">
        <section id="about" className="section">
          <h1 className="grey-text-3 font-bold text-center">
            Welcome ! <br />
          </h1>
        </section>
      </div>
    </div>
  </Jumbo>
);

Welcome.propTypes = {};
Welcome.defaultProps = {};

export default Welcome;
