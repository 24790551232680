import React from "react";
import withToken from "../../../auth/HOC/withToken";
import withUserBlock from "../arc/UserBlock";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import useToggle from "../../../hooks/useToggle";
import EditBlockDialog from "./EditBlockDialog";
import { List, ListItemIcon } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import navigate from "../../../../singletons/navigate";
import commons from "../commons";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Field from "../../../../layout/inputs/Field";
import useFormState from "../../../hooks/useFormState";
import { CheckCircle } from "@mui/icons-material";

const Blocks = ({
  model,
  fetch,
  onItemClick,
  size = 200,
  showCreate = true,
  showDefaultMenu = true,
  showFilter = false,
  containerData,
}) => {
  const [visible, setVisible] = useToggle(false);
  const [form, setForm] = useFormState({ filter: "" });
  const onSuccess = () => {
    fetch();
    setVisible(false);
  };

  const items = model.slice(0, size).reduce((state, item) => {
    if (form.filter) {
      const found =
        item.title.toLowerCase().indexOf(form.filter.toLowerCase()) > -1;
      return found ? state.concat(item) : state;
    }
    return state.concat(item);
  }, []);

  const isAlreadyPicked = (node, id) => {
    if (!node || !node.contents || node.contents.length < 1) return false;
    return node.contents.some((p) => {
      return p.block_id === id || isAlreadyPicked(p, id);
    });
  };

  return (
    <div>
      <EditBlockDialog
        open={visible}
        onClose={() => setVisible(false)}
        onSuccess={onSuccess}
      />

      {showFilter && (
        <Field
          model={form}
          prop={"filter"}
          placeholder={`Filter les ${commons.plural}`}
          onChange={setForm}
        />
      )}

      <List>
        {showDefaultMenu && (
          <ListItem button onClick={() => onItemClick({ type: "menu" })}>
            <ListItemText
              primary={"Votre menu"}
              primaryTypographyProps={{ style: { fontWeight: "bold" } }}
            />
            {isAlreadyPicked(containerData, "custom") && (
              <ListItemIcon children={<CheckCircle />} />
            )}
          </ListItem>
        )}
        {items.map((item) => (
          <ListItem
            key={item.id}
            button
            onClick={() =>
              onItemClick
                ? onItemClick(item)
                : navigate(commons.basePath + "/" + item.id)
            }
          >
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{ style: { fontWeight: "bold" } }}
            />
            {isAlreadyPicked(containerData, item.id) && (
              <ListItemIcon children={<CheckCircle />} />
            )}
          </ListItem>
        ))}
      </List>
      {showCreate && (
        <Button
          onClick={() => setVisible(true)}
          style={{ display: "block", width: "100%" }}
        >
          Créer {commons?.gender === "female" ? "une nouvelle" : "un nouveau"}{" "}
          {commons.singular}
        </Button>
      )}
    </div>
  );
};

export default withToken(withUserBlock(withARCLoader(Blocks)));
