import {useFetch} from 'use-http'
import {interpolate} from 'react-arc'
import config from '../../../../config/config'

function useMenuAddContents({token, menu}) {
    const url = interpolate(config.menus.contents, menu)
    const {post, response, loading, error} = useFetch(url, {
        cacheLife: 0,
        cachePolicy: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            'X-Auth-Token': token
        },
    })

    const handleAdd = ({type, id, parent = null}) => {
        return post({
            'menu_id': menu.id,
            'type': type,
            'type_id': id,
            'parent_id': parent
        })
    }

    return [error, loading, response, handleAdd]
}

export default useMenuAddContents