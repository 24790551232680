import {useFetch} from 'use-http'
import {interpolate} from 'react-arc'
import config from '../../../../config/config'

function useMenuDeleteNode({token, menu}) {
    const url = interpolate(config.menus.contents, menu)
    const {del, response, loading, error} = useFetch(url, {
        cacheLife: 0,
        cachePolicy: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            'X-Auth-Token': token
        },
    })

    const handleAdd = ({node}) => {
        return del('/'+node.id)
    }

    return [error, loading, response, handleAdd]
}

export default useMenuDeleteNode