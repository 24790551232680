import { useState } from 'react'

function useFormState(defaultState = {}) {
  const [state, setState] = useState(defaultState)
  const setFormItem = (prop, value) => {
    setState({
      ...state,
      [prop]: value
    })
  }
  const reset = (obj) => {
    setState({
      ...state,
      ...obj
    })
  }
  return [state, setFormItem, reset]
}

export default useFormState