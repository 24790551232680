import React from "react";

import BoardList from "./components/BoardList";
import withUserCheck from "../../auth/HOC/withUserCheck";
import withUser from "../../auth/HOC/withUser";
import Board from "./components/Board";

const BoardPage = (props) => {
  const boardId = props.match.params.id;
  return boardId ? <Board id={boardId} /> : <BoardList />;
};

export default withUser(withUserCheck(BoardPage));
