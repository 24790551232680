//import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { mixerConnector } from "react-arc";
import config from "../../../../config/config";
import ModelWithAuthContainer from "../../../auth/containers/ModelWithAuthContainer";
import createHOC from "../../../arc/createHOC";

/** DONT FORGET TO :
 [ ] DECLARE YOUR store : config/store.js  -> userFood
 [ ] ADD MODEL REQUIRED PROPS, in configUserFood
 [ ] TYPE THEM  const types = {id: PropType.string.isRequired} (for instance
 [ ] IMPORT ARCModel, createHOC, config (for your urls), from where you are in the project (use your IDE)
 **/

export const USER_FOOD_NAMESPACE = "USER_FOOD";

export const configUserFood = {
  name: "userFood",
  uppercaseName: USER_FOOD_NAMESPACE,
  modelProps: ["id"],
  collectionProps: [],
  paths: {
    item: config.foods.food,
    read: config.foods.food + "/{id}",
    update: config.foods.food + "/{id}",
    delete: config.foods.food + "/{id}",
    duplicate: config.foods.duplicate,
  },
  headers: {
    "Content-Type": "application/json",
    "X-Auth-Token": "{token}",
  },
  fetchOnce: false,
  maxPendingRequestsPerReducer: 3,
  requestFetchDelay: 100,
};

const types = {};

export class UserFoodContainerBase extends ModelWithAuthContainer {
  static defaultProps = {
    ARCConfig: configUserFood,
  };
  static propTypes = types;
}

const connector = mixerConnector(connect, configUserFood);
export const UserFoodContainer = connector(UserFoodContainerBase);

const withUserFood = createHOC({
  Container: UserFoodContainer,
  ARCConfig: configUserFood,
});
withUserFood.propTypes = types;

withUserFood.defaultProps = {};

export default withUserFood;
