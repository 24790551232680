import React, { PureComponent } from "react";
import Page from "../layout/page/Page";
import Panel from "../layout/panel/Panel";
//import SignUp from "../components/auth/components/user/SignUp"
import { Check } from "@mui/icons-material";
import PhenixConnectComponent from "../components/auth/components/user/LoginConnectComponent";

import SnackbarContent from "../layout/Snackbar/SnackbarContent";
import withUser from "../components/auth/HOC/withUser";
import navigate from "../singletons/navigate";
import { Divider } from "@mui/material";
import { Jumbo } from "../layout/page/Jumbo";

const Success = () => (
  <SnackbarContent
    message={
      <span>
        <b>One last step !</b>
        <br /> Please check your email and it's done !
      </span>
    }
    close
    color="success"
    icon={Check}
  />
);
const Activated = () => (
  <SnackbarContent
    message={
      <span>
        <b>Account Active</b>
        <br /> You can now sign in !
      </span>
    }
    close
    color="success"
    icon={Check}
  />
);

const Heading = () => {
  return (
    <Jumbo>
      <div style={{ height: 200 }} />
    </Jumbo>
  );
};

class SignInPage extends PureComponent {
  onChange = (isLogged) => {
    if (isLogged) navigate("/");
  };

  componentDidUpdate(prevProps, prevState) {
    const { isLogged } = this.props;
    if (isLogged) navigate("/");
  }

  componentDidMount() {
    const { isLogged } = this.props;
    if (isLogged) navigate("/");
  }

  render() {
    const { search } = this.props.location;
    const registration = search === "?status=awaiting-confirmation";
    const activated = search === "?status=account-activated";

    return (
      <Page header={<Heading />} className="full-width">
        <div className="container">
          <Panel
            style={{ maxWidth: "400px", margin: "-200px auto 0 auto" }}
            bodyStyle={{ padding: 0 }}
          >
            <div style={{ padding: 0 }}>
              {registration ? <Success /> : null}
              {activated ? <Activated /> : null}
              <PhenixConnectComponent
                onChange={this.onChange}
                style={{ width: "100%", padding: 0 }}
                loginStyle={{ padding: 20 }}
              >
                <div style={{ padding: 20 }}>
                  <h4>Se connecter</h4>
                </div>
                <Divider />
              </PhenixConnectComponent>
            </div>
          </Panel>
        </div>
      </Page>
    );
  }
}

SignInPage.propTypes = {};
SignInPage.defaultProps = {};

export default withUser(SignInPage);
