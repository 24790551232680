import React from "react";
import withUser from "../../../auth/HOC/withUser";
import { compose } from "redux";
import useQrCode from "../../../hooks/useQrCode";
import { getURL } from "./BoardQrCode";
import { Button } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";

function imageToPrint(source) {
  //eslint-disable-next-line no-useless-concat
  return (
    "<html><head><scri" +
    "pt>function step1(){\n" +
    "setTimeout('step2()', 10);}\n" +
    "function step2(){window.print();window.close()}\n" +
    //eslint-disable-next-line no-useless-concat
    "</scri" +
    "pt></head><body onload='step1()'>\n" +
    "<img src='" +
    source +
    "' /></body></html>"
  );
}

function printImage(source) {
  const pageLink = "about:blank";
  const pwa = window.open(pageLink, "_new");
  pwa.document.open();
  pwa.document.write(imageToPrint(source));
  pwa.document.close();
}

function BoardPrintQrCode({ user, id }) {
  const url = getURL({ userName: user.username, boardId: id });
  const [qrCode] = useQrCode(url);
  return (
    <div className="text-center">
      {!!qrCode && (
        <Button target="_blank" onClick={() => printImage(qrCode)}>
          Imprimer <br /> votre QR-CODE <OpenInNew />
        </Button>
      )}
    </div>
  );
}

export default compose(withUser)(BoardPrintQrCode);
