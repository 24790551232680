import React from "react";
//import PropTypes from 'prop-types'
import { Divider } from "@mui/material";
import Edit from "./Edit";
import Dialog from "@mui/material/Dialog";

function EditFoodDialog({
  id,
  duplicate,
  duplicateData = {},
  open,
  onClose,
  onSuccess,
}) {
  const {
    title,
    description,
    lang,
    price,
    price_icon,
    price_desc,
    price_2,
    price_icon_2,
    price_desc_2,
  } = duplicateData;

  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
      <div style={{ padding: "20px 40px" }}>
        <h4>{duplicate ? "Dupliquer" : "Éditer"}</h4>
      </div>
      <Divider />
      <div>
        <div style={{ minHeight: 200, padding: "20px 40px" }}>
          <Edit
            {...(id ? { id } : {})}
            {...(duplicate
              ? {
                  title,
                  description,
                  lang,
                  price,
                  price_icon,
                  price_desc,
                  price_2,
                  price_icon_2,
                  price_desc_2,
                }
              : {})}
            duplicate={duplicate}
            onSuccess={onSuccess}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default EditFoodDialog;
