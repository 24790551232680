import React from "react";

const LangText = ({ model, className = "", style }) => {
  const { lang } = model;
  if (lang === "fr") {
    return (
      <span className={className} style={style}>
        Français
      </span>
    );
  }
  if (lang === "es") {
    return (
      <span className={className} style={style}>
        Espagnol
      </span>
    );
  }
  if (lang === "en") {
    return (
      <span className={className} style={style}>
        Anglais
      </span>
    );
  }
  if (lang === "it") {
    return (
      <span className={className} style={style}>
        Italien
      </span>
    );
  }
  return lang;
};

export default LangText;
