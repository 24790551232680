import React from 'react'
import withUser from '../../../auth/HOC/withUser'
import {compose} from 'redux'
import {interpolate} from 'react-arc'
import config from '../../../../config/config'
import useQrCode from '../../../hooks/useQrCode'


export const getURL = ({userName, boardId}) => {
    return interpolate(config.desk, {userName: userName, boardId: boardId})
}

function BoardLink({user, id}) {
    const url = getURL({userName: user.username, boardId: id})
    const [qrCode] = useQrCode(url)
    return (<div className="text-center">
        {!!qrCode && (<img alt={'qr code'} src={qrCode}/>)}
    </div>)
}

BoardLink.propTypes = {}
BoardLink.defaultProps = {}

export default compose(withUser)(BoardLink)
