import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorkerRegistration";
import { reloadSession } from "./components/auth/actions/authActions";
import store from "./config/store";

function runApp() {
  const root = document.getElementById("root");
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    root
  );
}

store.dispatch(reloadSession()).then(() => {
  runApp();
});

serviceWorker.register({
  //onSuccess: () => store.dispatch({ type: SW_INIT }),
  //onUpdate: (registration) => store.dispatch({ type: SW_UPDATE, payload: registration }),
});

//registerServiceWorker();
