import React from "react";
import MediumPage from "../../../../layout/page/MediumPage";
import Panel from "../../../../layout/panel/Panel";
import withUser from "../../../auth/HOC/withUser";
import commons from "../commons";
import Categories from "./Categories";
import Welcome from "../../../home/Welcome";

function CategoryList({ user }) {
  return (
    <MediumPage header={<Welcome />} containerStyle={{ maxWidth: "100%" }}>
      <h1 style={{ color: "#FFF" }}>Vos {commons.plural}</h1>
      <p style={{ color: "#FFF" }}>Liste de vos {commons.plural}</p>
      <Panel bodyStyle={{ padding: 0 }}>
        <Categories id={user.id} />
      </Panel>
    </MediumPage>
  );
}

CategoryList.propTypes = {};
CategoryList.defaultProps = {};

export default withUser(CategoryList);
