export const commons = {
  basePath: "/menus",
  singular: "menu",
  plural: "menus",
};
export const commons_menu_title = {
  basePath: "/menus-titles",
  singular: "séparateur",
  plural: "séparateurs",
};

export default commons;
