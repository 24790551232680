import React from "react";

/** REDUX **/
//import store from './store'

/** ROUTES DEPENDENCIES **/
import { Router, Route } from "react-router";
import { ConnectedRouter } from "connected-react-router";

import history from "./history";
import ApplicationLayout from "../layout/application/ApplicationLayout";
import RouterHome from "../components/home/RouterHome";
import SignUpPage from "../pages/SignUpPage";

import SignInPage from "../pages/SignInPage";
// import SettingsProfilePage from "../pages/SettingsProfilePage"
// import SettingsTeamsPage from "../pages/SettingsTeamsPage"
// import SettingsTeamJoinPage from "../pages/SettingsTeamJoinPage"
import RouterMenus from "../components/editor/menus/RouterMenus";
import RouterMenusTitle from "../components/editor/menus/RouterMenusTitle";
import RouterCategories from "../components/editor/categories/RouterCategories";
import RouterFoods from "../components/editor/foods/RouterFoods";
import RouterBoards from "../components/editor/boards/RouterBoards";
import RouterBlocks from "../components/editor/blocks/RouterBlocks";

/** LAYOUT COMPONENTS **/

/** Pages **/
//basename={process.env.PUBLIC_URL}

const router = (
  <ConnectedRouter history={history}>
    <Router history={history}>
      <ApplicationLayout>
        <Route path="/boards" component={RouterBoards} />
        <Route path="/menus" component={RouterMenus} />
        <Route path="/menus-titles" component={RouterMenusTitle} />
        <Route path="/categories" component={RouterCategories} />
        <Route path="/foods" component={RouterFoods} />
        <Route path="/blocks" component={RouterBlocks} />
        <Route exact path="/" component={RouterHome} />
        <Route exact path="/sign-up" component={SignUpPage} />
        <Route exact path="/sign-in" component={SignInPage} />
      </ApplicationLayout>
    </Router>
  </ConnectedRouter>
);

export default router;
