import React from "react";
//import PropTypes from 'prop-types'
import { Divider } from "@mui/material";
import Edit from "./Edit";
import Dialog from "@mui/material/Dialog";

function EditMenuDialog({
  id,
  duplicate,
  duplicateData,
  type = "menu",
  open,
  onClose,
  onSuccess,
}) {
  const { title, description, lang } = duplicateData;
  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
      <div style={{ padding: "20px 40px" }}>
        <h4>{!!id ? "Éditer" : "Créer"}</h4>
      </div>
      <Divider />
      <div style={{ minHeight: 200 }}>
        <div style={{ padding: "20px 40px" }}>
          <Edit
            {...(id ? { id, type } : { type })}
            duplicate={duplicate}
            {...(duplicate
              ? {
                  title,
                  description,
                  lang,
                }
              : {})}
            onSuccess={onSuccess}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default EditMenuDialog;
