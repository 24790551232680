import {useEffect, useState} from 'react'
import QRCode from 'qrcode'

function useQrCode(defaultValue) {

    const [encoded, setEncoded] = useState(defaultValue)
    const [qrCode, setQrCode] = useState(null)

    const handleNewQrCode = (text) => {
        setQrCode(null)
        setEncoded(text)
    }

    useEffect(() => {
        if (encoded && !qrCode) {
            QRCode.toDataURL(encoded)
                .then(url => {
                    setQrCode(url)
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }, [encoded, qrCode])

    return [qrCode, handleNewQrCode]
}

export default useQrCode