import React from 'react'
import {filterARCCONFIG} from './utils'

const ComponentLoader = ({ Wrapped, ARCConfig, ...props }) => {
  const defaultModel = JSON.parse(JSON.stringify(ARCConfig?.defaultModel || {}))
  const data = {
    loading: props.loading,
    loaded: props.loaded,
    error: props.error,
    model: !props.error && props.loaded ? props.model : defaultModel,
    collection: !props.error && props.loaded ? props.collection : [],
    fetch: props.fetch,
    create: props.create,
    update: props.update,
    remove: props.remove
  }
  return <Wrapped {...props.originalProps} {...data} />
}

function createHOC({ Container, ARCConfig }) {
  return function HOC(Wrapped) {
    return function ARCComponent(props) {
      return (
          <Container
              {...props}
              originalProps={filterARCCONFIG(props)}
              component={ComponentLoader}
              ARCConfig={ARCConfig}
              Wrapped={Wrapped}
          />
      )
    }
  }
}

export default createHOC
