import React from "react";
import PropTypes from "prop-types";
import { Drawer, List } from "@mui/material";
import SidebarMenu from "../../pages/SidebarMenu";
import "./sidebar.scss";
import { withStyles } from "@mui/styles";

const drawerWidth = 300;

const styles = (theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    //position: 'relative',
    backgroundColor: "#323232",
    top: 64,
    height: "calc(100% - 64px)",
    width: drawerWidth,
    color: "#FFF",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    backgroundColor: "#323232",
  },
});

// import './sidebar.css'
// import SidebarMenu from '../../pages/SidebarMenu'
//
// const sideBarStyle = {height:'calc( 100% - 90px)', top: '90px', color:'#FFF'}
//
// export class SideBarComponent extends Component {
//     render() {
//         return (<Drawer containerStyle={sideBarStyle} width={100} open={this.props.open}
//                         onRequestClose={() => this.props.toggler(true)}>
//             <SidebarMenu />
//         </Drawer>)
//     }
// }
//
// export default SideBarComponent

function SideBarComponent(props) {
  const { classes, toggler } = props;
  return (
    <Drawer
      type=""
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
      open={props.open}
      onClose={() => toggler(false)}
    >
      <div className={classes.drawerInner}>
        <List className={classes.list}>
          <SidebarMenu toggler={toggler} />
        </List>
      </div>
    </Drawer>
  );
}

SideBarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SideBarComponent);
