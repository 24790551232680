//import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {mixerConnector} from 'react-arc'
import config from '../../../../config/config'
import ModelWithAuthContainer from '../../../auth/containers/ModelWithAuthContainer'
import createHOC from '../../../arc/createHOC'


/** DONT FORGET TO :
 [ ] DECLARE YOUR store : config/store.js  -> userCategory
 [ ] ADD MODEL REQUIRED PROPS, in configUserCategory
 [ ] TYPE THEM  const types = {id: PropType.string.isRequired} (for instance
 [ ] IMPORT ARCModel, createHOC, config (for your urls), from where you are in the project (use your IDE)
 **/

export const USER_CATEGORY_NAMESPACE = 'USER_CATEGORY'

export const configUserCategory = {
    name: 'userCategory',
    uppercaseName: USER_CATEGORY_NAMESPACE,
    modelProps: ['id'],
    collectionProps: [],
    paths: {
        item: config.categories.category,
        read: config.categories.category + '/{id}',
        update: config.categories.category + '/{id}',
        delete: config.categories.category + '/{id}',
    },
    headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': '{token}'
    },
    fetchOnce: false,
    maxPendingRequestsPerReducer: 3,
    requestFetchDelay: 100
}

const types = {}

export class UserCategoryContainerBase extends ModelWithAuthContainer {
    static defaultProps = {
        ARCConfig: configUserCategory,
    }
    static propTypes = types
}

const connector = mixerConnector(connect, configUserCategory)
export const UserCategoryContainer = connector(UserCategoryContainerBase)

const withUserCategory = createHOC({
    Container: UserCategoryContainer,
    ARCConfig: configUserCategory
})
withUserCategory.propTypes = types

withUserCategory.defaultProps = {}

export default withUserCategory