import React from "react";
import useToggle from "../../../hooks/useToggle";
import Edit from "./Edit";
import { Button } from "@mui/material";

function MenuHeader({ isBoard = false, data, onSuccess, EditComponent }) {
  const [visible, setVisible] = useToggle(false);
  const handleEdit = () => setVisible(true);
  const closeHandler = () => setVisible(false);
  const handleSave = () => {
    if (onSuccess) {
      onSuccess();
    }
    closeHandler();
  };
  const { id } = data;
  const WrappedEdit = EditComponent || Edit;
  return (
    <div className="menu-header">
      {visible ? (
        <div
          className="animated fadeIn"
          style={{
            margin: 10,
            backgroundColor: "#FFF",
            borderRadius: 4,
            padding: "10px 20px",
          }}
        >
          <WrappedEdit {...(id ? { id } : {})} isBoard onSuccess={handleSave} />
        </div>
      ) : (
        <div className="animated fadeIn">
          <h4>{data.title}</h4>
          <p>{data.description}</p>
          <Button
            onClick={handleEdit}
            size="small"
            style={{ color: "rgba(255,255,255,.6)" }}
          >
            Editer
          </Button>
        </div>
      )}
    </div>
  );
}

export default MenuHeader;
