import React from "react";
import { Button } from "@mui/material";

const styles = {
  container: {
    backgroundColor: "#FFF",
    color: "#434343",
    width: "100%",
    marginBottom: 10,
    boxShadow: "0 1px 3px rgba(0,0,0,.2)",
  },
};

function BigButton({ title, onClick, children, style }) {
  return (
    <Button
      style={{ ...styles.container, ...style }}
      variant={"contained"}
      color="primary"
      onClick={onClick}
    >
      {children || title}
    </Button>
  );
}

export default BigButton;
