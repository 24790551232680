import React from "react";
import MenuList from "./components/MenuList";
import withUserCheck from "../../auth/HOC/withUserCheck";
import withUser from "../../auth/HOC/withUser";
import Menu from "./components/Menu";

const MenuPage = (props) => {
  const menuId = props.match.params.id;

  return menuId ? <Menu id={menuId} /> : <MenuList />;
  // return (
  //   <Page className="full-width">
  //     {menuId ? <Menu id={menuId} /> : <MenuList />}
  //   </Page>
  // );
};

export default withUser(withUserCheck(MenuPage));
