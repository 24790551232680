import React from "react";
import Panel from "../../../../layout/panel/Panel";
import MediumPage from "../../../../layout/page/MediumPage";
import withToken from "../../../auth/HOC/withToken";
import { compose } from "redux";
import withARCLoader from "../../../auth/HOC/withARCLoader";
import { withUseARC } from "react-arc";
import navigate from "../../../../singletons/navigate";
import { Column, Row } from "../../../../layout/grid";
import commons from "../commons";
import { configUserFood } from "../arc/UserFood";
import useToggle from "../../../hooks/useToggle";
import EditFoodDialog from "./EditFoodDialog";
import FoodPrice from "./FoodPrice";
import BigButton from "../../../../layout/buttons/BigButton";

function Food({ response, arc }) {
  const [visible, setVisible] = useToggle(false);

  const handleDelete = () =>
    arc.remove().then(() => navigate(commons.basePath));
  // const handleOther = () => {
  //     console.log('hello')
  // }
  const handleEdit = () => setVisible(true);
  const closeHandler = () => setVisible(false);

  return (
    <MediumPage containerStyle={{ maxWidth: "100%" }}>
      <EditFoodDialog
        id={response.id}
        open={visible}
        onClose={closeHandler}
        onSuccess={() => {
          closeHandler();
          arc.get();
        }}
      />
      <Row>
        <Column size={9}>
          <Panel>
            <Row>
              <Column size={9}>
                <div>
                  <strong>{response.title}</strong>
                </div>
                {response.description && (
                  <div className="text-muted">{response.description}</div>
                )}
              </Column>
              <Column size={3}>
                {response.price && <FoodPrice price={response.price} />}
              </Column>
            </Row>
          </Panel>
        </Column>
        <Column size={3}>
          <Panel>
            <BigButton
              style={{ width: "100%", marginBottom: 10 }}
              onClick={handleEdit}
            >
              Modifier
            </BigButton>
            <BigButton
              style={{ color: "#E33", width: "100%" }}
              variant={"contained"}
              color="primary"
              onClick={handleDelete}
            >
              Supprimer
            </BigButton>
          </Panel>
        </Column>
      </Row>
    </MediumPage>
  );
}

Food.propTypes = {};
Food.defaultProps = {};

export default compose(
  withToken,
  withUseARC(configUserFood),
  withARCLoader
)(Food);
