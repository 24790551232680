import config from "../../../config/config"

export const profileConfig = {
  name: "profile",
  //used in the reducers
  uppercaseName: "PROFILE",
  // useful to map objects in collection
  modelProps: ['id'],
  // can be empty (might be usefull if you need paging...)
  collectionProps: [],
  // path to your rest server
  paths: {
    item: config.profile + '/{id}',
    collection: '/'
  },
  headers: {
    "content-type": "application/json",
    "X-Auth-Token": ""
  },
  defaultModel: {
    "username": "",
    "password": "",
    "passwordConfirm": "",
    "email": ""
  },
  fetchOnce: false
}

export default profileConfig