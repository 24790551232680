import { useFetch } from "use-http";
import { interpolate } from "react-arc";
import config from "../../../../config/config";

function useBoardOrderNode({ token, data, baseURL = config.boards.contents }) {
  const url = interpolate(baseURL, data);
  const { put, response, loading, error } = useFetch(url, {
    cacheLife: 0,
    cachePolicy: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  });

  const handleRequest = ({ node, body }) => {
    return put("/" + node.id, body);
  };

  return [error, loading, response, handleRequest];
}

export default useBoardOrderNode;
