import React from "react";

const EmojiLang = ({ model }) => {
  const { lang } = model;
  if (lang === "fr") {
    return <span>🇫🇷</span>;
  }
  if (lang === "es") {
    return <span>🇪🇸</span>;
  }
  if (lang === "en") {
    return <span>🇬🇧</span>;
  }
  if (lang === "it") {
    return <span>🇮🇹</span>;
  }
  return lang;
};

export default EmojiLang;
